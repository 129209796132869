import React from 'react';
import { motion } from 'framer-motion';

export default function AtTheOffice() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 510 340"
      className="w-full"
    >
      <g id="banner-svg">
        <g id="background">
          <path
            id="Vector"
            fill="#A8A8A8"
            d="M200.797 300.166v16.455h50.606v-16.455h-50.606z"
          />
          <path
            id="Vector_2"
            fill="#DADADA"
            d="M198.676 295.569v4.597h54.495v-4.597H198.676z"
          />
          <path
            id="Vector_3"
            fill="#DADADA"
            d="M225.923 316.622h25.48v-16.456h1.768v-4.597h-28.309v4.175l1.061 16.878z"
          />
          <path
            id="Vector_4"
            fill="#DADADA"
            d="M218.137 302.818h-10.261v2.475h10.261v-2.475z"
          />
          <path
            id="Vector_5"
            fill="#fff"
            d="M219.735 308.394h-13.178v5.229h13.178v-5.229z"
          />
          <path
            id="Vector_6"
            fill="#A8A8A8"
            d="M205.448 279.126v16.456H256.054v-16.456h-50.606z"
          />
          <path
            id="Vector_7"
            fill="#DADADA"
            d="M203.327 274.523v4.603h54.495v-4.603H203.327z"
          />
          <path
            id="Vector_8"
            fill="#DADADA"
            d="M230.574 295.582h25.48v-16.456h1.768v-4.603h-28.308v4.175l1.06 16.884z"
          />
          <path
            id="Vector_9"
            fill="#DADADA"
            d="M222.788 281.778h-10.261v2.476h10.261v-2.476z"
          />
          <path
            id="Vector_10"
            fill="#fff"
            d="M224.386 287.354h-13.178v5.23h13.178v-5.23z"
          />
          <path
            id="Vector_11"
            fill="#A8A8A8"
            d="M34.177 199.131s-2.652 1.999-2.652 4.651v13.546h19.76v-14.151s-.278-3.475-2.597-4.08l-14.511.034z"
          />
          <path
            id="Vector_12"
            fill="#DADADA"
            d="M33.028 216.594a36.805 36.805 0 01-.293-4.162 3.345 3.345 0 012.312-3.074c1.04-.285 2.135.096 3.196.272a8.446 8.446 0 005.114-.761c1.36-.68 2.557-1.7 4.039-1.836.972-.089 2.094.367 2.36 1.305a3.23 3.23 0 010 1.36c-.32 2.088.088 4.686.088 6.692-5.528.251-11.26.074-16.796.19"
          />
          <path
            id="Vector_13"
            fill="#DADADA"
            d="M30.274 219.245l2.978-1.917h18.034l2.89 1.917H30.274z"
          />
          <path
            id="Vector_14"
            fill="#A8A8A8"
            d="M29.716 175.175v20.169h24.48v23.902H30.274v5.732h41.235v-49.803a4.288 4.288 0 00-2.647-3.971 4.291 4.291 0 00-1.644-.327H34a4.298 4.298 0 00-4.284 4.298z"
          />
          <path
            id="Vector_15"
            fill="#DADADA"
            d="M71.509 175.63c0 .102-9.357.177-20.897.177s-20.896-.075-20.896-.177 9.357-.19 20.896-.19c11.54 0 20.897.095 20.897.19z"
          />
          <path
            id="Vector_16"
            fill="#DADADA"
            d="M59.908 224.978c-.095 0-.177-12.084-.177-26.982 0-14.899.082-26.976.177-26.976.095 0 .177 12.077.177 26.976 0 14.898-.075 26.982-.177 26.982z"
          />
          <path
            id="Vector_17"
            fill="#DADADA"
            d="M27.5 210.8l-.246-6.12a8.288 8.288 0 011.36-4.984 4.377 4.377 0 013.604-2.183h16.47v1.611H32.49s-2.917.306-3.298 4.298a39.028 39.028 0 000 7.364l-1.693.014z"
          />
          <path
            id="Vector_18"
            fill="#DADADA"
            d="M49.728 303.178h-7.235v13.042h7.235v-13.042z"
          />
          <path
            id="Vector_19"
            fill="#DADADA"
            d="M81.702 303.178h-7.235v13.042h7.235v-13.042z"
          />
          <path
            id="Vector_20"
            fill="#DADADA"
            d="M86.523 224.978H26.595v78.2h59.928v-78.2z"
          />
          <path
            id="Vector_21"
            fill="#A8A8A8"
            d="M73.99 224.978H15.62v78.2h58.37v-78.2z"
          />
          <path
            id="Vector_22"
            fill="#A8A8A8"
            d="M28.016 303.178h-7.235v13.042h7.235v-13.042z"
          />
          <path
            id="Vector_23"
            fill="#A8A8A8"
            d="M69.618 303.178h-7.235v13.042h7.235v-13.042z"
          />
          <path
            id="Vector_24"
            fill="#DADADA"
            d="M69.36 294.243v-1.347-3.835c0-3.325 0-8.16-.047-14.123l.081.081-50.096.034.116-.115v19.305l-.116-.116 35.884.055 10.438.034h2.795-2.768l-10.41.034-35.966.047h-.115v-19.475h.115l50.096.034h.081v.082c0 6.004 0 10.839-.04 14.178V293.91c-.048.224-.048.333-.048.333z"
          />
          <path
            id="Vector_25"
            fill="#DADADA"
            d="M45.812 284.587a2.46 2.46 0 01-.096-.354 1.452 1.452 0 00-1.31-1.051c-.2-.012-.4.017-.587.085a1.437 1.437 0 00-.918 1.32 1.418 1.418 0 00.784 1.262 1.42 1.42 0 001.48-.127c.266-.189.46-.462.551-.775a3.22 3.22 0 01.096-.36.68.68 0 010 .381 1.53 1.53 0 01-.544.89 1.59 1.59 0 01-1.524.252 1.616 1.616 0 01-.98-.941 1.615 1.615 0 011.182-2.163 1.6 1.6 0 011.322.316c.278.223.47.535.544.884a.68.68 0 010 .381z"
          />
          <path
            id="Vector_26"
            fill="#DADADA"
            d="M69.36 271.123v-1.347-3.828c0-3.325 0-8.16-.047-14.13l.081.081-50.096.041.116-.122v19.305l-.116-.109 35.884.048 10.438.034h2.795-2.768l-10.41.034-35.966.054h-.115v-19.482h.115l50.096.034h.081v.082c0 6.004 0 10.839-.04 14.178v4.8c-.048.218-.048.327-.048.327z"
          />
          <path
            id="Vector_27"
            fill="#DADADA"
            d="M45.812 261.474a3.296 3.296 0 01-.096-.361 1.448 1.448 0 00-.55-.775 1.417 1.417 0 00-1.681.007 1.412 1.412 0 00-.487 1.609 1.41 1.41 0 00.808.839 1.454 1.454 0 001.91-.993c.026-.12.057-.238.096-.354a.68.68 0 010 .381 1.534 1.534 0 01-.544.884 1.59 1.59 0 01-2.3-.333 1.62 1.62 0 01.776-2.455 1.6 1.6 0 011.524.252c.279.225.47.54.544.891a.68.68 0 010 .408z"
          />
          <path
            id="Vector_28"
            fill="#DADADA"
            d="M69.36 248.316v-1.34-3.835c0-3.325 0-8.16-.048-14.124l.082.082H19.298l.116-.116v19.299l-.116-.109 35.884.054H69.36 55.264l-35.966.055h-.115v-19.387h.115l50.096.041h.082v.081c0 6.005 0 10.833-.041 14.171v4.801c-.075.211-.075.327-.075.327z"
          />
          <path
            id="Vector_29"
            fill="#DADADA"
            d="M45.812 238.68a3.279 3.279 0 01-.096-.36 1.45 1.45 0 00-.55-.776 1.41 1.41 0 00-1.48-.128 1.418 1.418 0 00-.568 2.006 1.408 1.408 0 001.326.667 1.42 1.42 0 00.722-.273c.265-.19.46-.463.55-.776a3.22 3.22 0 01.096-.36.68.68 0 010 .381c-.075.35-.267.664-.544.891a1.598 1.598 0 01-1.524.251 1.608 1.608 0 01-.876-2.29 1.607 1.607 0 011.775-.8c.227.052.44.153.625.295.277.227.47.541.544.891a.68.68 0 010 .381z"
          />
          <path
            id="Vector_30"
            fill="#DADADA"
            d="M70.91 224.978c0 .095-9.084.177-20.298.177-11.213 0-20.298-.082-20.298-.177 0-.095 9.085-.177 20.298-.177 11.214 0 20.298.075 20.298.177z"
          />
          <path
            id="Vector_31"
            fill="#FAFAFA"
            d="M294.658 107.664h-52.7v47.152h52.7v-47.152z"
          />
          <path
            id="Vector_32"
            fill="#DADADA"
            d="M294.658 154.815v-.877-2.523-9.574c0-8.235-.041-20.006-.062-34.177l.116.123h-52.693l.143-.143v47.151l-.123-.122 38.121.061h13.593H280.208l-38.196.061h-.122v-.122V107.385H294.855v.116c0 14.198-.048 25.996-.061 34.252 0 4.114 0 7.337-.034 9.52v2.502c0 .571-.102 1.04-.102 1.04z"
          />
          <path
            id="Vector_33"
            fill="#DADADA"
            d="M262.167 116.042c0 .082-2.774.143-6.195.143-3.42 0-6.194-.061-6.194-.143 0-.082 2.767-.136 6.194-.136 3.428 0 6.195.061 6.195.136z"
          />
          <path
            id="Vector_34"
            fill="#DADADA"
            d="M284.498 119.585c0 .074-7.622.143-17 .143-9.377 0-17-.069-17-.143 0-.075 7.616-.143 17-.143s17 .061 17 .143z"
          />
          <path
            id="Vector_35"
            fill="#DADADA"
            d="M252.09 125.582h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_36"
            fill="#DADADA"
            d="M257.557 125.582h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_37"
            fill="#DADADA"
            d="M263.119 125.48h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_38"
            fill="#DADADA"
            d="M268.484 125.582h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_39"
            fill="#DADADA"
            d="M273.952 125.582h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_40"
            fill="#DADADA"
            d="M279.419 125.582h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_41"
            fill="#94B7A5"
            d="M284.879 125.582h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_42"
            fill="#94B7A5"
            d="M252.09 130.302h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_43"
            fill="#DADADA"
            d="M257.557 130.302h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_44"
            fill="#94B7A5"
            d="M263.017 130.302h-2.223v2.223h2.223v-2.223z"
          />
          <path
            id="Vector_45"
            fill="#DADADA"
            d="M268.484 130.302h-2.223v2.223h2.223v-2.223z"
          />
          <path
            id="Vector_46"
            fill="#DADADA"
            d="M273.952 130.302h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_47"
            fill="#DADADA"
            d="M279.514 130.2h-2.224v2.223h2.224V130.2z"
          />
          <path
            id="Vector_48"
            fill="#DADADA"
            d="M284.879 130.302h-2.223v2.223h2.223v-2.223z"
          />
          <path
            id="Vector_49"
            fill="#DADADA"
            d="M252.09 135.021h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_50"
            fill="#DADADA"
            d="M257.659 134.919h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_51"
            fill="#DADADA"
            d="M263.017 135.021h-2.223v2.223h2.223v-2.223z"
          />
          <path
            id="Vector_52"
            fill="#94B7A5"
            d="M268.484 135.021h-2.223v2.223h2.223v-2.223z"
          />
          <path
            id="Vector_53"
            fill="#DADADA"
            d="M273.952 135.021h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_54"
            fill="#DADADA"
            d="M279.419 135.021h-2.224v2.223h2.224v-2.223z"
          />
          <path
            id="Vector_55"
            fill="#DADADA"
            d="M284.879 135.021h-2.223v2.223h2.223v-2.223z"
          />
          <path
            id="Vector_56"
            fill="#DADADA"
            d="M252.09 139.74h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_57"
            fill="#DADADA"
            d="M257.557 139.74h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_58"
            fill="#DADADA"
            d="M263.017 139.74h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_59"
            fill="#DADADA"
            d="M268.586 139.638h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_60"
            fill="#DADADA"
            d="M273.952 139.74h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_61"
            fill="#94B7A5"
            d="M279.419 139.74h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_62"
            fill="#DADADA"
            d="M284.879 139.74h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_63"
            fill="#DADADA"
            d="M252.09 144.459h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_64"
            fill="#94B7A5"
            d="M257.557 144.459h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_65"
            fill="#DADADA"
            d="M263.017 144.459h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_66"
            fill="#DADADA"
            d="M268.484 144.459h-2.223v2.224h2.223v-2.224z"
          />
          <path
            id="Vector_67"
            fill="#DADADA"
            d="M273.952 144.459h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_68"
            fill="#DADADA"
            d="M279.514 144.364h-2.224v2.224h2.224v-2.224z"
          />
          <path
            id="Vector_69"
            fill="#DADADA"
            d="M284.879 144.459h-2.223v2.224h2.223v-2.224z"
          />

          <path
            id="Vector_94"
            fill="#DADADA"
            d="M253.769 146.792l-.714-2.496 1.435.068.129 2.305-.85.123z"
          />
          <path
            id="Vector_95"
            fill="#DADADA"
            d="M251.688 146.717l-1.632 8.86-4.127 11.71 23.263-3.353-9.391-10.22-3.917-7.596-4.196.599z"
          />
          <path
            id="Vector_96"
            fill="#DADADA"
            d="M205.931 208.882v-.367a7.394 7.394 0 012.081-5.366 7.377 7.377 0 015.301-2.243 7.387 7.387 0 017.381 7.609v.367h-14.763z"
          />
          <path
            id="Vector_97"
            fill="#DADADA"
            d="M213.296 201.423a.294.294 0 01-.279-.218l-9.785-37.284a.294.294 0 01.52-.253.292.292 0 01.051.103l9.778 37.285a.3.3 0 01-.211.36l-.074.007z"
          />
          <path
            id="Vector_98"
            fill="#DADADA"
            d="M210.712 202.348a.294.294 0 01-.279-.218l-9.785-37.284a.294.294 0 01.52-.254.297.297 0 01.051.104l9.778 37.264a.3.3 0 01-.211.36l-.074.028z"
          />
          <path
            id="Vector_99"
            fill="#DADADA"
            d="M200.192 164.213a1.852 1.852 0 003.155 1.317 1.851 1.851 0 00-1.305-3.16 1.848 1.848 0 00-1.85 1.843z"
          />
          <path
            id="Vector_100"
            fill="#DADADA"
            d="M202.205 164.458l-.333-.483 34.666-23.915 20.284 11.532-.292.51-19.958-11.349-34.367 23.705z"
          />
          <path
            id="Vector_101"
            fill="#DADADA"
            d="M237.858 141.621l-34.136 23.637.333.48 34.136-23.637-.333-.48z"
          />
          <path
            id="Vector_102"
            fill="#DADADA"
            d="M235.45 141.331a1.658 1.658 0 001.987 1.62 1.658 1.658 0 00-.328-3.286 1.652 1.652 0 00-1.175.489 1.639 1.639 0 00-.484 1.177z"
          />
          <path
            id="Vector_103"
            fill="#DADADA"
            d="M254.062 53.87l-29.703 9.975h67.109l-27.886-9.975h-9.52z"
          />
          <path
            id="bulb-filling"
            fill="currentColor"
            className="text-secondary-300 dark:text-yellow-300 opacity-10 dark:opacity-90 dark:shadow-yellow transition-all duration-300 ease-in-out"
            d="M256 64c1 3 0 3.5-3 8-1.5 1.5-1 9.5 6 9.5 8-1 6-8.5 5.5-9.5-3-5-2.5-7-2.285-8H256z"
          />
          <path
            id="Vector_104"
            fill="currentColor"
            className="text-neutral-300 dark:text-yellow-500 dark:shadow-yellow transition-all duration-300 ease-in-out"
            d="M262.398 63.165c-.11.58-.185 1.166-.224 1.755a8.756 8.756 0 001.108 4.61c.463.904 1.061 1.863 1.53 2.992a6.62 6.62 0 01.395 3.862 6.922 6.922 0 01-2.095 3.734 6.383 6.383 0 01-4.284 1.638 6.76 6.76 0 01-4.345-1.482 6.803 6.803 0 01-1.931-7.514 12.54 12.54 0 011.788-2.863 11.673 11.673 0 001.456-2.312c.281-.626.44-1.3.469-1.985a3.702 3.702 0 00-.401-1.694c.102.118.184.251.244.395.184.409.284.85.293 1.299a5.357 5.357 0 01-.422 2.04 11.406 11.406 0 01-1.441 2.386c-.692.861-1.266 1.81-1.707 2.822a6.493 6.493 0 00-.186 3.878 6.493 6.493 0 002.063 3.29 6.404 6.404 0 004.12 1.4 6.058 6.058 0 004.08-1.536 6.674 6.674 0 002.04-3.55 6.342 6.342 0 00-.346-3.686c-.442-1.101-1.027-2.04-1.483-2.985a8.68 8.68 0 01-1.02-4.76c.031-.443.102-.882.211-1.312.012-.144.042-.286.088-.422z"
          />
          <path
            id="Vector_105"
            fill="currentColor"
            className="text-neutral-300 dark:text-yellow-500 dark:shadow-yellow transition-all duration-300 ease-in-out"
            d="M259.944 63.798c-.036.42-.098.838-.184 1.25a34.292 34.292 0 00-.34 3.4 27.726 27.726 0 00.224 5.046c.037.21.132.405.272.565a.556.556 0 00.517.183c.367-.074.565-.632.49-1.074a2.12 2.12 0 00-4.182-.061.952.952 0 00.557 1.067.845.845 0 00.966-.557c.151-.438.227-.897.224-1.36.034-.449.055-.884.062-1.313.006-.785-.028-1.57-.102-2.352a22.622 22.622 0 00-.572-3.4c-.19-.782-.319-1.218-.292-1.224.054.094.097.195.129.299.082.197.184.496.299.89.324 1.112.552 2.25.68 3.4.089.793.137 1.59.143 2.387 0 .427-.013.868-.041 1.326.001.489-.079.973-.238 1.435a1.36 1.36 0 01-.516.626.976.976 0 01-.857.122 1.293 1.293 0 01-.796-1.428 2.472 2.472 0 012.461-2.054 2.466 2.466 0 012.415 2.109c.04.288.002.58-.109.85a.954.954 0 01-.68.605.897.897 0 01-.83-.286 1.49 1.49 0 01-.353-.727 25.849 25.849 0 01-.157-5.128 26.3 26.3 0 01.456-3.4c.088-.4.156-.68.218-.91.03-.102.076-.199.136-.286z"
          />
          <path
            id="Vector_106"
            fill="#DADADA"
            d="M258.828 2.856c.026.17.038.344.034.517 0 .367 0 .857.034 1.469 0 1.312 0 3.148.055 5.399 0 4.597.034 10.88.054 17.823.021 6.942-.041 13.226-.054 17.822 0 2.251-.041 4.08-.055 5.44 0 .606 0 1.095-.034 1.462a2.98 2.98 0 01-.034.517 2.418 2.418 0 01-.034-.517c0-.367 0-.856-.034-1.462 0-1.319 0-3.155-.061-5.44 0-4.596 0-10.88-.047-17.822-.048-6.943.034-13.226.047-17.823 0-2.251.048-4.08.061-5.4 0-.611 0-1.101.034-1.468a2.418 2.418 0 01.034-.517z"
          />
          <path
            id="Vector_107"
            fill="#DADADA"
            d="M254.062 53.87l1.836-8.249h6.12l1.564 8.248h-9.52z"
          />
          <path
            id="Vector_108"
            fill="#DADADA"
            d="M155.292 51.918h-3.339v71.767h3.339V51.918z"
          />
          <path
            id="Vector_109"
            fill="#A8A8A8"
            d="M151.395 52.476h-47.817a.047.047 0 00-.048.047v71.217c0 .026.021.047.048.047h47.817a.047.047 0 00.048-.047V52.523a.047.047 0 00-.048-.047z"
          />
          <path
            id="Vector_110"
            fill="#94B7A5"
            d="M151.953 124.338h-48.96a.048.048 0 01-.048-.048V51.966a.047.047 0 01.048-.048h48.96a.056.056 0 01.032.016.05.05 0 01.015.032v72.324a.048.048 0 01-.015.032.052.052 0 01-.032.016zm-47.818-1.108h46.703a.048.048 0 00.047-.048V53.081a.053.053 0 00-.047-.055h-46.703a.053.053 0 00-.047.054v70.102a.048.048 0 00.047.048z"
          />
          <path
            id="Vector_111"
            fill="#FAFAFA"
            d="M146.656 56.875h-38.25a.047.047 0 00-.048.048v62.369c0 .027.021.048.048.048h38.25a.047.047 0 00.047-.048v-62.37a.047.047 0 00-.047-.047z"
          />
          <path
            id="Vector_112"
            fill="#94B7A5"
            d="M138.21 74.582h-21.447a.04.04 0 00-.041.041v25.153c0 .023.018.041.041.041h21.447a.04.04 0 00.041-.04V74.622a.04.04 0 00-.041-.04z"
          />
          <path
            id="Vector_113"
            fill="#DADADA"
            d="M136.551 96.73c0 .082-1.041.544-3.06.313a7.476 7.476 0 01-3.434-1.36 7.003 7.003 0 01-2.673-4.203 6.034 6.034 0 012.04-5.739 5.445 5.445 0 011.551-.911 4.156 4.156 0 011.938-.218 3.4 3.4 0 011.938.844 2.537 2.537 0 01.761 2.257 3.724 3.724 0 01-1.06 1.938 7.06 7.06 0 01-1.626 1.19 8.92 8.92 0 01-3.869 1.109 7.665 7.665 0 01-4.121-.939 7.483 7.483 0 01-3.039-3.148 6.426 6.426 0 01-.544-4.407 6.576 6.576 0 012.325-3.63 5.828 5.828 0 011.945-.98 4.864 4.864 0 012.19-.068c.769.146 1.468.54 1.992 1.122.489.593.756 1.339.755 2.108a4.081 4.081 0 01-.524 2.04 5.327 5.327 0 01-1.224 1.476 8.26 8.26 0 01-3.053 1.625 7.844 7.844 0 01-3.209.245 6.537 6.537 0 01-2.795-1.068 6.06 6.06 0 01-2.666-4.23 5.106 5.106 0 01.993-3.617 4.325 4.325 0 011.822-1.462c.504-.19.796-.184.803-.136.007.047-.225.177-.633.449a4.765 4.765 0 00-1.421 1.53 4.524 4.524 0 00-.585 3.094 5.107 5.107 0 002.374 3.325 5.867 5.867 0 004.896.462 6.806 6.806 0 002.448-1.36 3 3 0 001.142-2.292 1.65 1.65 0 00-1.476-1.625 3.187 3.187 0 00-1.407.062 3.959 3.959 0 00-1.36.68 4.839 4.839 0 00-1.68 2.665 4.669 4.669 0 00.415 3.203 5.798 5.798 0 002.312 2.407 5.998 5.998 0 003.189.741 7.289 7.289 0 003.135-.856 5.424 5.424 0 001.251-.884 2.1 2.1 0 00.633-1.034.891.891 0 00-.252-.83 1.865 1.865 0 00-1.04-.455 2.527 2.527 0 00-1.218.102 4.083 4.083 0 00-1.142.619 4.75 4.75 0 00-1.482 2.04 4.642 4.642 0 00-.245 2.346 6.048 6.048 0 002.04 3.638 7.3 7.3 0 002.951 1.468c1.89.408 2.985.184 2.999.354z"
          />
        </g>
        <g id="floor">
          <path
            id="Vector_114"
            fill="#263238"
            d="M495.842 316.2c0 .095-108.534.177-242.392.177s-242.414-.061-242.414-.177c0-.116 108.515-.177 242.414-.177 133.898 0 242.392.102 242.392.177z"
          />
        </g>
        <motion.g
          id="man-in-suit"
          animate={{ rotate: [1.5, 0, 1.5] }}
          transition={{ duration: 2.4, repeat: Infinity }}
        >
          <path
            id="Vector_115"
            fill="#B78876"
            d="M353.552 124.304c1.435-2.081 2.224-3.638 3.237-4.95 1.251-1.619 1.659-2.374 1.36-2.591-.299-.218-.251-.191-2.944 2.421 0 0 3.815-3.768 2.346-3.598-.646.075-3.849 3.169-3.849 3.169 2.802-2.251 3.495-3.543 3.135-3.944-.524-.591-4.379 3.203-4.379 3.203s2.998-2.836 3.4-3.495c.401-.66-.531-.497-1.238-.15s-5.059 3.305-6.338 4.08a.869.869 0 01-.717.172.884.884 0 01-.595-.437c-.768-2.04-2.448-3.203-3.053-3.305a.685.685 0 00-.629.427.679.679 0 00.16.743 7.883 7.883 0 011.142 1.877c.259.527.425 1.095.49 1.679.074.68.115 1.258.129 1.748l-1.938 10.812 6.8 4.76 3.481-12.621z"
          />
          <path
            id="Vector_116"
            fill="#AA6550"
            d="M353.6 120.795a18.873 18.873 0 003.597-3.672 20.01 20.01 0 00-3.597 3.672z"
          />
          <path
            id="Vector_117"
            fill="#AA6550"
            d="M352.492 119.87a11.631 11.631 0 002.148-1.713 11.506 11.506 0 001.918-1.965 22.074 22.074 0 00-4.08 3.678h.014z"
          />
          <path
            id="Vector_118"
            fill="#AA6550"
            d="M351.342 118.898a19.95 19.95 0 003.89-3.4 9.827 9.827 0 00-2.04 1.584 9.529 9.529 0 00-1.85 1.816z"
          />
          <path
            id="Vector_119"
            fill="#B78876"
            d="M306.748 91.018a10.025 10.025 0 0110.812 9.391c.279 4.658.537 9.697.503 12.24-.068 5.44-5.515 6.297-5.515 6.297s.782 9.465.68 10.995l-14.416.68-1.305-29.124a10.027 10.027 0 019.241-10.452v-.027z"
          />
          <path
            id="Vector_120"
            fill="#AA6550"
            d="M312.535 118.857a12.62 12.62 0 01-7.099-2.04s1.679 3.917 7.112 3.4l-.013-1.36z"
          />
          <path
            id="Vector_121"
            fill="#B78876"
            d="M298.003 103.36c-.088-.041-3.74-1.108-3.624 2.618.115 3.726 3.835 2.836 3.842 2.72.007-.116-.218-5.338-.218-5.338z"
          />
          <path
            id="Vector_122"
            fill="#AA6550"
            d="M297.038 107.311s-.068.047-.177.102a.63.63 0 01-.483 0 1.614 1.614 0 01-.789-1.415c-.019-.32.032-.64.15-.938a.76.76 0 01.476-.51.323.323 0 01.387.163c.055.102.034.177.055.184.02.007.075-.068.041-.218a.405.405 0 00-.157-.231.494.494 0 00-.36-.082.91.91 0 00-.68.599 2.27 2.27 0 00-.191 1.047 1.69 1.69 0 001 1.578.687.687 0 00.592-.116c.129-.034.149-.156.136-.163z"
          />
          <path
            id="Vector_123"
            fill="#263238"
            d="M316.159 103.87a.781.781 0 01-.755.789.74.74 0 01-.795-.68.766.766 0 01.748-.789.75.75 0 01.802.68z"
          />
          <path
            id="Vector_124"
            fill="#263238"
            d="M316.139 102.347c-.095.102-.68-.333-1.523-.333-.844 0-1.456.421-1.544.319-.088-.102.054-.224.32-.421a2.135 2.135 0 011.237-.381c.435-.001.859.137 1.211.394.258.197.36.381.299.422z"
          />
          <path
            id="Vector_125"
            fill="#263238"
            d="M307.931 103.87a.787.787 0 01-.755.789.75.75 0 01-.802-.68.784.784 0 01.748-.789.755.755 0 01.809.68z"
          />
          <path
            id="Vector_126"
            fill="#263238"
            d="M307.904 102.503c-.095.102-.68-.333-1.523-.333-.843 0-1.449.422-1.537.32-.088-.102.048-.225.32-.422a2.13 2.13 0 011.23-.381c.438-.002.865.136 1.218.395.251.197.34.381.292.421z"
          />
          <path
            id="Vector_127"
            fill="#263238"
            d="M310.944 108.712a5.656 5.656 0 011.36-.245c.217 0 .414-.061.455-.211.015-.22-.035-.44-.143-.632-.204-.517-.414-1.061-.632-1.626a27.688 27.688 0 01-1.421-4.263 27.963 27.963 0 011.761 4.134c.211.571.408 1.122.605 1.639.134.26.172.558.109.843a.533.533 0 01-.354.313 1.452 1.452 0 01-.36.048c-.458.06-.922.06-1.38 0z"
          />
          <path
            id="Vector_128"
            fill="#263238"
            d="M308.482 109.29c.136 0 .136.904.918 1.55s1.741.544 1.748.68c.006.136-.211.177-.626.184a2.267 2.267 0 01-1.462-.51 1.992 1.992 0 01-.68-1.292c-.061-.388.041-.612.102-.612z"
          />
          <path
            id="Vector_129"
            fill="#263238"
            d="M308.122 99.226c-.082.224-.918.115-1.898.238-.979.122-1.781.387-1.91.176-.055-.095.081-.306.401-.53a3.313 3.313 0 012.903-.32c.361.15.538.327.504.436z"
          />
          <path
            id="Vector_130"
            fill="#263238"
            d="M315.826 99.96c-.143.19-.721 0-1.414 0-.694 0-1.279.122-1.415-.075-.061-.095 0-.285.286-.469.344-.22.748-.326 1.156-.306a1.92 1.92 0 011.129.38c.244.144.326.34.258.47z"
          />
          <path
            id="Vector_131"
            fill="#263238"
            d="M297.65 103.244a5.792 5.792 0 000 1.714 1.974 1.974 0 00.952 1.36c.938.469 2.114-.252 2.529-1.21.33-.998.402-2.062.211-3.094a14.968 14.968 0 01.061-4.264 6.118 6.118 0 012.04-3.651 3.864 3.864 0 014.012-.579c.738.48 1.446 1.001 2.122 1.565a3.292 3.292 0 002.251.564 2.126 2.126 0 001.727-1.435 2.303 2.303 0 00-.884-2.285 5.207 5.207 0 00-2.38-.959 12.816 12.816 0 00-7.065.551 9.268 9.268 0 00-5.134 4.76c-1.082 2.55-.347 4.57-.504 7.405"
          />
          <path
            id="Vector_132"
            fill="#DADADA"
            d="M352.995 127.432l-8.915-2.149-5.882 15.953-16.252-14.538c-1.734-1.265-4.712-1.422-6.977-1.707h-.177c-.877-.102-1.305-.231-1.836-.293-3.916 2.945-10.621 3.135-14.47.096 0 0-2.917.68-4.658.992-5.488 1.054-9.438 5.046-10.54 8.738l6.875 15.844 1.312 15.049-3.801 13.477a2.324 2.324 0 00.298 1.924 2.336 2.336 0 001.654 1.028l34.034.516.557-.272a6.332 6.332 0 00.911-4.311c-1.468-6.446-.442-25.677-.754-31.579 6.677 6.487 14.042 11.866 19.42 8.534 4.482-2.795 9.201-27.302 9.201-27.302z"
          />
          <path
            id="Vector_133"
            fill="#DADADA"
            d="M297.391 125.759l.789-5.447 7.419 2.727 2.094 3.686 1.299-4.046 4.066-1.401 1.741 3.618-2.985 4.223-5.596 1.135-8.827-4.495z"
          />
          <path
            id="Vector_134"
            fill="#263238"
            d="M307.822 127.568s-.108-.061-.278-.224a8.009 8.009 0 01-.68-.735 10.92 10.92 0 01-1.789-3.08h.272l-1.713 4.311c-.3.741-.592 1.455-.864 2.135l-.088.225-.177-.17a421.597 421.597 0 01-3.699-3.625l-1.061-1.081a2.203 2.203 0 01-.354-.422c.158.096.304.21.435.34l1.122 1.014c.952.87 2.272 2.108 3.788 3.543l-.265.054.85-2.135c.639-1.564 1.237-3.026 1.754-4.305l.143-.353.129.36A12.216 12.216 0 00307 126.5c.503.66.85 1.041.822 1.068z"
          />
          <path
            id="Vector_135"
            fill="#263238"
            d="M314.799 124.896a5.531 5.531 0 01-.87 1.196 12.074 12.074 0 01-2.836 2.503l-.163.095-.068-.184c-.245-.68-.524-1.36-.823-2.114a69.727 69.727 0 01-1.19-3.101h.293c-.245 1.231-.551 2.237-.735 2.944a6.339 6.339 0 01-.319 1.115 4.26 4.26 0 01.149-1.156c.136-.72.395-1.74.605-2.958l.096-.537.197.517c.34.925.768 1.965 1.217 3.08.299.742.571 1.462.809 2.129l-.231-.082a13.357 13.357 0 002.849-2.353c.317-.385.658-.751 1.02-1.094z"
          />
          <path
            id="Vector_136"
            fill="#263238"
            d="M310.508 183.015s0-.197.041-.564c.041-.367.102-.938.17-1.625.068-.687.15-1.564.225-2.564.034-.503.068-1.04.108-1.605.041-.564.048-1.169.075-1.802.116-2.522.109-5.535.048-8.88-.061-3.346-.272-7.011-.531-10.88-.557-7.705-1.319-14.654-1.89-19.68-.286-2.488-.517-4.515-.68-5.956-.075-.68-.129-1.218-.177-1.619a3.305 3.305 0 01-.034-.571c.05.185.084.374.102.564.061.395.136.932.238 1.612.197 1.401.476 3.4.789 5.943.632 5.019 1.428 11.975 1.986 19.679.265 3.856.435 7.535.503 10.88.068 3.346 0 6.365-.116 8.895-.034.632-.068 1.23-.095 1.802-.027.571-.102 1.101-.143 1.605-.088 1.006-.163 1.87-.279 2.57-.115.7-.176 1.217-.231 1.612a3.23 3.23 0 01-.109.584z"
          />
          <path
            id="Vector_137"
            fill="#263238"
            d="M338.014 147.397c-.17-1.07-.227-2.155-.17-3.237a15.52 15.52 0 01.17-3.23c.17 1.068.227 2.15.17 3.23a15.45 15.45 0 01-.17 3.237z"
          />
          <path
            id="Vector_138"
            fill="#263238"
            d="M340.286 147.805c0 .306-.456-.558-1.456-3.332a14.871 14.871 0 01-.897-3.523 29.085 29.085 0 011.217 3.4 31.5 31.5 0 011.136 3.455z"
          />
          <path
            id="Vector_139"
            fill="#13CB6E"
            d="M318.322 296.929l-.517 12.355s12.383 4.679 12.512 6.97l-23.991-.142v-19.285l11.996.102z"
          />
          <path
            id="Vector_140"
            fill="#DADADA"
            d="M310.393 308.203a.987.987 0 00-.68 1.115.943.943 0 001.101.68 1.03 1.03 0 00.721-1.183.984.984 0 00-1.224-.591"
          />
          <g id="Group" opacity="0.6">
            <path
              id="Vector_141"
              fill="#fff"
              d="M306.326 316.112l.075-1.945 22.977.863s1.061.47.939 1.224l-23.991-.142z"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_142"
            fill="#263238"
            d="M318.192 309.169c0 .115-.591.163-1.169.537-.578.374-.884.864-.993.816-.109-.048.068-.721.782-1.163.714-.442 1.428-.299 1.38-.19z"
          />
          <path
            id="Vector_143"
            fill="#263238"
            d="M320.701 310.223c0 .115-.476.313-.877.802-.401.49-.523 1.02-.639 1.013-.115-.006-.183-.68.326-1.271.51-.592 1.19-.653 1.19-.544z"
          />
          <path
            id="Vector_144"
            fill="#263238"
            d="M322.116 313.242c-.109 0-.265-.564.041-1.204.306-.639.85-.89.904-.788.055.102-.292.435-.537.965-.245.531-.292 1.02-.408 1.027z"
          />
          <path
            id="Vector_145"
            fill="#263238"
            d="M318.002 306.34c-.054.102-.592-.061-1.251 0-.66.061-1.177.252-1.238.15-.061-.102.435-.517 1.217-.558.782-.041 1.326.313 1.272.408z"
          />
          <path
            id="Vector_146"
            fill="#263238"
            d="M318.056 303.844a2.268 2.268 0 01-1.196 0 5.487 5.487 0 01-1.938-.761 1.614 1.614 0 01-.361-.286.52.52 0 01-.054-.625.678.678 0 01.517-.266 1.45 1.45 0 01.448.068 4.126 4.126 0 011.85.993c.558.517.741.959.68.979-.061.021-.32-.326-.884-.761a4.834 4.834 0 00-1.068-.605 4.143 4.143 0 00-.68-.218c-.258-.068-.469-.075-.537.041-.068.115 0 .061 0 .136.078.081.167.149.265.204.202.137.413.26.633.367.371.186.758.339 1.156.456.396.062.787.155 1.169.278z"
          />
          <path
            id="Vector_147"
            fill="#263238"
            d="M317.907 304.014a1.96 1.96 0 01-.313-1.162c-.018-.447.04-.893.17-1.32.079-.252.184-.496.313-.727a.98.98 0 01.768-.599.566.566 0 01.503.334c.058.141.097.29.116.442.044.268.044.541 0 .809-.07.449-.25.874-.524 1.237-.469.619-.958.762-.972.721-.014-.041.374-.285.734-.877.208-.344.34-.729.388-1.129a2.348 2.348 0 000-.68c-.034-.258-.129-.462-.224-.435-.096.027-.32.184-.422.388a3.888 3.888 0 00-.299.68 4.223 4.223 0 00-.245 1.196c-.054.66.068 1.102.007 1.122z"
          />
          <path
            id="Vector_148"
            fill="#13CB6E"
            d="M276.937 293.298l-3.897 15.463-6.609 11.342a1.644 1.644 0 00.158 1.879c.144.172.322.312.522.413a1.635 1.635 0 001.496 0c2.965-1.598 13.851-7.541 14.076-8.691.258-1.36 5.195-16.041 5.195-16.041l-10.941-4.365z"
          />
          <g id="Group_2" opacity="0.6">
            <path
              id="Vector_149"
              fill="#fff"
              d="M267.118 322.395l15.857-9.758-.177.68a2.45 2.45 0 01-.986 1.36c-1.298.877-4.916 3.223-13.151 7.711a1.65 1.65 0 01-1.543 0v.007z"
              opacity="0.6"
            />
          </g>
          <g id="Group_3" opacity="0.6">
            <path
              id="Vector_150"
              fill="#fff"
              d="M281.194 306.809a.98.98 0 00-.606 1.17.955.955 0 001.156.612 1.034 1.034 0 00.633-1.244.98.98 0 00-1.272-.504"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_151"
            fill="#263238"
            d="M272.49 316.649a5.117 5.117 0 00-1.02-1.571 4.87 4.87 0 00-1.673-.823c0-.047.224-.109.598-.061a2.622 2.622 0 012.081 1.85c.095.367.054.605.014.605z"
          />
          <path
            id="Vector_152"
            fill="#263238"
            d="M270.824 318.403c-.089.048-.449-.591-1.177-1.04-.47-.231-.953-.433-1.448-.605a1.828 1.828 0 011.632.312c.809.49 1.081 1.313.993 1.333z"
          />
          <path
            id="Vector_153"
            fill="#263238"
            d="M275.257 314.575a17.134 17.134 0 00-1.713-1.727 17.505 17.505 0 00-2.136-1.163 4.47 4.47 0 013.849 2.89z"
          />
          <path
            id="Vector_154"
            fill="#263238"
            d="M276.835 309.971c-.068.082-.823-.51-1.911-.857-1.088-.346-2.04-.319-2.04-.421a3.154 3.154 0 012.156.088 3.199 3.199 0 011.795 1.19z"
          />
          <path
            id="Vector_155"
            fill="#263238"
            d="M278.12 305.463a8.126 8.126 0 00-1.965-.945 7.827 7.827 0 00-2.183-.048c0-.041.204-.19.619-.292a3.53 3.53 0 013.107.741c.293.285.422.517.422.544z"
          />
          <path
            id="Vector_156"
            fill="#263238"
            d="M276.393 303.668a1.305 1.305 0 01.136-.585c.2-.508.501-.971.884-1.36a1.807 1.807 0 011.006-.68.673.673 0 01.68.34c.111.239.128.51.048.761a2.303 2.303 0 01-2.516 1.517 2.679 2.679 0 01-2.163-2.04.924.924 0 01.164-.762.68.68 0 01.741-.149c.354.188.652.467.863.809.328.446.572.949.721 1.482.072.191.102.395.089.599a7.425 7.425 0 00-1.007-1.932 2.107 2.107 0 00-.775-.68.343.343 0 00-.401.082.591.591 0 00-.075.503 2.382 2.382 0 001.89 1.755 2.004 2.004 0 002.163-1.258.781.781 0 000-.531.36.36 0 00-.374-.197c-.333.085-.632.27-.857.53a6.118 6.118 0 00-1.217 1.796z"
          />
          <path
            id="Vector_157"
            fill="#13CB6E"
            d="M304.565 125.514l1.687.878-.32 2.835-2.625-.551 1.258-3.162z"
          />
          <g id="Group_4" opacity="0.4">
            <path
              id="Vector_158"
              fill="#000"
              d="M304.565 125.514l1.687.878-.32 2.835-2.625-.551 1.258-3.162z"
              opacity="0.4"
            />
          </g>
          <path
            id="Vector_159"
            fill="#13CB6E"
            d="M308.428 126.215l1.271-1.115 1.17 2.964-1.673.803-.768-2.652z"
          />
          <path
            id="Vector_160"
            fill="#13CB6E"
            d="M306.564 129.921l-.788 33.531 5.664 5.603 2.747-7.446-5.44-31.865-2.183.177z"
          />
          <path
            id="Vector_161"
            fill="#13CB6E"
            d="M306.598 125.358c.388-.048.918-.15 1.36-.238a.923.923 0 011.014.462l.714 1.32a.954.954 0 01.095.625l-.286 1.476a.927.927 0 01-.775.741l-1.618.326a.936.936 0 01-1.02-.496l-.762-1.591a.974.974 0 010-.803l.564-1.271a.938.938 0 01.714-.551z"
          />
          <path
            id="Vector_162"
            fill="#263238"
            d="M308.951 125.712a3.298 3.298 0 01-.428.537c-.279.319-.68.755-1.109 1.231-.428.476-.843.897-1.135 1.196-.152.175-.32.335-.503.476.123-.193.267-.373.428-.537.279-.319.68-.755 1.115-1.231.435-.476.837-.897 1.136-1.203.151-.171.317-.328.496-.469z"
          />
          <path
            id="Vector_163"
            fill="#263238"
            d="M307.299 127.255a3.842 3.842 0 01-.782-.68 3.643 3.643 0 01-.68-.755c.289.192.552.421.782.68.254.226.482.479.68.755z"
          />
          <path
            id="Vector_164"
            fill="#263238"
            d="M309.189 129.404c.098-.653.232-1.3.401-1.938a1.91 1.91 0 00-.401-1.258 1.072 1.072 0 01-.197-.551s.082.204.279.503c.088.15.217.327.326.544.124.241.169.515.129.782-.108.551-.258 1.014-.346 1.36-.043.193-.107.38-.191.558z"
          />
          <path
            id="Vector_165"
            fill="#263238"
            d="M323.68 182.24l-2.38 123.08a1.508 1.508 0 01-1.523 1.482l-14.341-.129a1.51 1.51 0 01-1.069-.461 1.513 1.513 0 01-.427-1.082l2.21-105.89-18.653 105.155a2.72 2.72 0 01-2.754 2.231l-12.029-.415a2.718 2.718 0 01-2.508-1.991 2.713 2.713 0 01-.069-1.13L289 181.764l34.68.476z"
          />
          <path
            id="Vector_166"
            fill="#455A64"
            d="M278.542 300.111s0-.102.034-.292l.129-.843c.122-.762.299-1.857.53-3.251.469-2.835 1.149-6.915 1.993-11.947 1.679-10.098 3.998-24.025 6.562-39.406 2.563-15.382 4.8-29.342 6.392-39.44.782-5.039 1.421-9.119 1.863-11.961.217-1.401.394-2.489.51-3.251l.143-.843c.034-.19.061-.286.061-.286.006.095.006.191 0 .286 0 .211-.061.49-.109.85-.102.762-.258 1.856-.449 3.264-.401 2.849-1.033 6.929-1.761 11.975-1.53 10.111-3.747 24.065-6.29 39.44a5283.371 5283.371 0 01-6.664 39.392c-.884 5.025-1.598 9.092-2.094 11.927a584.84 584.84 0 00-.592 3.237c-.068.36-.122.639-.163.843-.041.204-.095.306-.095.306z"
          />
          <path
            id="Vector_167"
            fill="#455A64"
            d="M315.296 300.56c-.096 0 .068-25.051.36-55.944.292-30.892.605-55.943.68-55.943.075 0-.068 25.044-.36 55.95-.293 30.906-.592 55.937-.68 55.937z"
          />
          <path
            id="Vector_168"
            fill="#455A64"
            d="M321.722 300.988c0 .096-3.91.17-8.732.17-4.821 0-8.731-.074-8.731-.17 0-.095 3.91-.163 8.731-.163 4.822 0 8.732.075 8.732.163z"
          />
          <path
            id="Vector_169"
            fill="#455A64"
            d="M288.354 300.322a2.472 2.472 0 01-.68.068l-1.911.068-6.297.15a223.05 223.05 0 01-6.303.061c-.809 0-1.462 0-1.911-.048a2.47 2.47 0 01-.68-.061c.223-.049.451-.072.68-.068l1.911-.061 6.303-.157c2.462-.047 4.692-.068 6.304-.054.809 0 1.462 0 1.911.041a3.17 3.17 0 01.673.061z"
          />
          <path
            id="Vector_170"
            fill="#455A64"
            d="M308.652 183.722c.043.71.027 1.422-.048 2.129a211.6 211.6 0 01-.36 5.12c-.088 1-.143 1.952-.265 2.829a5.443 5.443 0 01-.762 2.224 3.16 3.16 0 01-1.163 1.081c-.167.104-.36.161-.557.163a3.537 3.537 0 001.509-1.36 5.48 5.48 0 00.68-2.135c.102-.85.143-1.809.232-2.809.163-1.999.326-3.801.462-5.113.034-.716.125-1.428.272-2.129z"
          />
          <g id="Group_5" opacity="0.3">
            <path
              id="Vector_171"
              fill="#000"
              d="M290.149 171.992a6.42 6.42 0 005.59 1.605c1.407-.285 2.72-1.054 4.182-.959 1.571.102 2.869 1.204 4.304 1.85 2.673 1.217 5.794.83 8.636.068 1.306-.347 2.666-.816 3.509-1.877a6.28 6.28 0 001.081-2.91 30 30 0 00.422-5.787 5.53 5.53 0 00-.932-3.441 3.934 3.934 0 00-2.591-1.305 10.011 10.011 0 00-2.951.163 48.765 48.765 0 00-21.406 9.18"
              opacity="0.3"
            />
          </g>
          <path
            id="Vector_172"
            fill="#DADADA"
            d="M303.96 165.6s-18.02 8.052-19.849 8.48c-6.079 1.36-13.029-4.406-11.098-10.948.714-2.434 10.404-29.362 10.404-29.362l9.221 12.736-3.903 13.042 10.2-.68 5.025 6.732z"
          />
          <path
            id="Vector_173"
            fill="#263238"
            d="M298.119 158.95a3.444 3.444 0 01-.714.102l-1.972.163c-1.673.129-4.019.293-6.691.463h-.225l.068-.218.599-2.04a615.097 615.097 0 012.38-7.786l.754-2.346c.082-.292.189-.576.32-.85-.042.3-.11.596-.204.884-.17.619-.388 1.421-.68 2.38-.571 1.999-1.36 4.76-2.292 7.806-.204.68-.408 1.36-.605 2.04l-.156-.204c2.665-.156 5.018-.278 6.698-.353l1.972-.075c.25-.014.5-.002.748.034z"
          />
          <path
            id="Vector_174"
            fill="#263238"
            d="M301.92 166.654c.034.082-3.917 1.782-8.84 3.808-4.923 2.027-8.915 3.598-8.949 3.516-.034-.082 3.917-1.788 8.84-3.808 4.923-2.02 8.949-3.57 8.949-3.516z"
          />
          <path
            id="Vector_175"
            fill="#455A64"
            d="M299.758 158.352l-1.422-6.916a.894.894 0 01.878-1.068l17.897.089a.888.888 0 01.877.993l-1.849 15.83a1.245 1.245 0 01-1.238 1.095l-11.669-.095a1.244 1.244 0 01-1.19-.932l-2.284-8.996z"
          />
          <path
            id="Vector_176"
            fill="#B78876"
            d="M298.18 154.714a1.883 1.883 0 011.761 1.196 4.027 4.027 0 001.197 1.918c.877.476 2.394.864 3.196.469.802-.394 3.992-3.264 4.549-2.944.558.319 1.041 1.033.483 1.673-.558.639-3.516 2.556-2.958 3.196.558.639 6.8-2.17 7.752-1.925.952.245 1.516.837.959 1.224-.558.388-4.794 1.979-5.032 2.543 0 0 6.392-1.679 6.63-1.278.238.401.639 1.034 0 1.278-.639.245-6.154 1.673-6.63 1.918-.796.316-1.624.544-2.469.68-.17 0-1.421.422-2.57.816a5.644 5.644 0 01-3.978-.102l-.646-.265a2.55 2.55 0 01-1.578-2.503l.136-2.176-.68-1.802-.122-3.916z"
          />
          <path
            id="Vector_177"
            fill="#FAFAFA"
            d="M308.924 153.034a.678.678 0 01-.813.667.678.678 0 01-.495-.927.678.678 0 011.308.26z"
          />
        </motion.g>
        <g id="desk">
          <path
            id="Vector_178"
            fill="#455A64"
            d="M385.9 208.998h-53.625v-1.999h51.633a2 2 0 011.992 1.999z"
          />
          <path
            id="Vector_179"
            fill="#455A64"
            d="M371.09 177.494h-36.544a2.271 2.271 0 00-2.271 2.271v26.533a2.272 2.272 0 002.271 2.272h36.544a2.272 2.272 0 002.271-2.272v-26.533a2.271 2.271 0 00-2.271-2.271z"
          />
          <path
            id="Vector_180"
            fill="#FAFAFA"
            d="M371.647 178.976h-37.658v27.676h37.658v-27.676z"
          />
          <path
            id="Vector_181"
            fill="#263238"
            d="M371.64 206.652v-.51-1.482-5.61-20.074l.041.034H334.03l.054-.048v27.676l-.048-.04h34.939-34.986v-.041V178.833h37.699v27.214c0 .333-.048.605-.048.605z"
          />
          <path
            id="Vector_182"
            fill="#DADADA"
            d="M366.01 186.422h-25.983l.048-.048v12.778l-.048-.048h23.875-5.44L340 199.24h-.048v-12.866h26.031l.027.048z"
          />
          <path
            id="Vector_183"
            fill="#DADADA"
            d="M366.01 188.571s-5.828.048-13.008.048c-7.181 0-13.002 0-13.002-.048s5.821-.041 13.002-.041c7.18 0 13.008.021 13.008.041z"
          />
          <path
            id="Vector_184"
            fill="#DADADA"
            d="M365.84 190.284s-5.821.048-13.002.048c-7.18 0-13.008 0-13.008-.048 0-.047 5.821-.04 13.008-.04 7.188 0 13.002.02 13.002.04z"
          />
          <path
            id="Vector_185"
            fill="#DADADA"
            d="M366.01 192.093s-5.828.048-13.008.048c-7.181 0-13.002 0-13.002-.048 0-.047 5.821-.047 13.002-.047 7.18 0 13.008.02 13.008.047z"
          />
          <path
            id="Vector_186"
            fill="#DADADA"
            d="M366.01 193.854s-5.828.048-13.008.048c-7.181 0-13.002 0-13.002-.048 0-.047 5.821-.047 13.002-.047 7.18 0 13.008-.007 13.008.047z"
          />
          <path
            id="Vector_187"
            fill="#DADADA"
            d="M366.01 195.616s-5.828.047-13.008.047c-7.181 0-13.002 0-13.002-.047 0-.048 5.821-.048 13.002-.048 7.18 0 13.008.021 13.008.048z"
          />
          <path
            id="Vector_188"
            fill="#DADADA"
            d="M340 197.377s5.821-.048 13.002-.048c7.18 0 13.008 0 13.008.048 0 .047-5.821.041-13.008.041-7.188 0-13.002-.021-13.002-.041z"
          />
          <path
            id="Vector_189"
            fill="#DADADA"
            d="M345.148 199.397s-.041-2.904-.041-6.488c0-3.583 0-6.487.041-6.487.04 0 .047 2.904.047 6.487 0 3.584-.02 6.488-.047 6.488z"
          />
          <path
            id="Vector_190"
            fill="#DADADA"
            d="M350.363 199.397s-.047-2.904-.047-6.488c0-3.583 0-6.487.047-6.487.048 0 .048 2.904.048 6.487 0 3.584-.021 6.488-.048 6.488z"
          />
          <path
            id="Vector_191"
            fill="#DADADA"
            d="M355.579 199.397s-.048-2.904-.048-6.488c0-3.583 0-6.487.048-6.487.047 0 .047 2.904.047 6.487 0 3.584.014 6.488-.047 6.488z"
          />
          <path
            id="Vector_192"
            fill="#DADADA"
            d="M360.794 199.397s-.047-2.904-.047-6.488c0-3.583 0-6.487.047-6.487.048 0 .048 2.904.048 6.487 0 3.584-.02 6.488-.048 6.488z"
          />
          <path
            id="Vector_193"
            fill="#DADADA"
            d="M366.01 199.397s-.048-2.904-.048-6.488c0-3.583 0-6.487.048-6.487s.048 2.904.048 6.487c0 3.584-.021 6.488-.048 6.488z"
          />

          <path
            id="Vector_221"
            fill="#263238"
            d="M359.672 203.667c-.942.054-1.886.054-2.828 0a20.851 20.851 0 012.828 0z"
          />
          <path
            id="Vector_222"
            fill="#263238"
            d="M137.394 316.22l11.234-99.375 9.533 3.359-15.572 96.016h-5.195z"
          />
          <path
            id="Vector_223"
            fill="#455A64"
            d="M420.07 208.882H132.6v12.574h287.47v-12.574z"
          />
          <path
            id="Vector_224"
            fill="#263238"
            d="M193.501 209.073a1.437 1.437 0 01-.095.136l-.3.374a5.974 5.974 0 01-1.448 1.156c-.86.479-1.8.794-2.774.931a19.403 19.403 0 01-3.876.17 36.965 36.965 0 01-4.658-.537 41.937 41.937 0 00-5.311-.632c-3.767-.136-7.861.85-11.955 2.319-4.093 1.468-7.935 3.134-11.6 4.148a31.773 31.773 0 01-5.291 1.047 22.1 22.1 0 01-4.699 0 16.09 16.09 0 01-6.446-2.04 10.135 10.135 0 01-1.992-1.489s.054 0 .142.088l.381.286a13.42 13.42 0 001.537.992 16.246 16.246 0 006.406 1.932c1.545.154 3.102.143 4.644-.034a32.01 32.01 0 005.229-1.068c3.638-1.027 7.48-2.72 11.56-4.168 4.08-1.449 8.249-2.462 12.077-2.312 1.797.11 3.584.337 5.352.68 1.528.283 3.072.474 4.624.571 1.279.089 2.563.05 3.835-.116a8.291 8.291 0 002.72-.85 7.4 7.4 0 001.938-1.584z"
          />
          <path
            id="Vector_225"
            fill="#263238"
            d="M162.051 209.263a7.784 7.784 0 01-.871.741 19.822 19.822 0 01-2.618 1.687 22.835 22.835 0 01-4.29 1.747 23.628 23.628 0 01-5.604.878 21.458 21.458 0 01-10.023-2.04 6.385 6.385 0 01-2.414-1.979 3.532 3.532 0 01-.401-.762c-.054-.183-.082-.279-.068-.285.167.336.36.659.578.965a6.592 6.592 0 002.421 1.836 22.294 22.294 0 009.894 1.938 23.035 23.035 0 009.785-2.509 23.308 23.308 0 002.652-1.578c.301-.239.622-.453.959-.639z"
          />

          <path
            id="Vector_234"
            fill="#263238"
            d="M258.536 209.073c.022.268.009.538-.041.802a9.675 9.675 0 01-.564 2.122 12.158 12.158 0 01-4.42 5.651 29.349 29.349 0 01-6.549 3.012c-.877.292-1.591.503-2.087.632a3.607 3.607 0 01-.789.164c.238-.127.488-.228.748-.3l2.04-.727a34.282 34.282 0 006.44-3.074 12.63 12.63 0 004.379-5.44c.361-.922.643-1.873.843-2.842z"
          />
          <path
            id="Vector_235"
            fill="#263238"
            d="M191.801 221.456a4.75 4.75 0 01.68-.68c.462-.422 1.163-.993 2.04-1.673a58.963 58.963 0 017.33-4.76c2.985-1.646 5.78-2.958 7.807-3.883a97.816 97.816 0 012.407-1.081c.288-.147.591-.263.904-.347a4.858 4.858 0 01-.843.469l-2.366 1.183c-1.993.986-4.76 2.333-7.739 3.972a71.508 71.508 0 00-7.351 4.61c-.904.68-1.625 1.197-2.108 1.585a5.292 5.292 0 01-.761.605z"
          />

          <path
            id="Vector_238"
            fill="#263238"
            d="M272.245 203.238h-38.74v5.787h38.74v-5.787z"
          />
          <path
            id="Vector_239"
            fill="#DADADA"
            d="M243.746 204.578h-10.241v3.114h10.241v-3.114z"
          />
          <path
            id="Vector_240"
            fill="#13CB6E"
            d="M275.699 197.452H236.96v5.786h38.739v-5.786z"
          />
          <path
            id="Vector_241"
            fill="#DADADA"
            d="M275.706 198.839h-26.275v2.897h26.275v-2.897z"
          />
          <path
            id="Vector_242"
            fill="#263238"
            d="M231.18 197.445h38.739v-5.787H231.18v5.787z"
          />
          <path
            id="Vector_243"
            fill="#DADADA"
            d="M259.678 196.112h10.241v-3.114h-10.241v3.114z"
          />
        </g>
        <g id="sitting-man">
          <path
            id="Vector_244"
            fill="#13CB6E"
            d="M367.309 294.488l4.651 11.471s-10.139 8.548-9.52 10.751l22.562-8.16-6.439-18.19-11.254 4.128z"
          />
          <path
            id="Vector_245"
            fill="#DADADA"
            d="M378.549 302.457a.99.99 0 011.02.823.945.945 0 01-.809 1.013 1.03 1.03 0 01-1.068-.87c0-.483.476-1.034.952-.966"
          />
          <g id="Group_6" opacity="0.6">
            <path
              id="Vector_246"
              fill="#fff"
              d="M385.03 308.55l-.728-1.802-21.359 8.493s-.843.803-.476 1.469l22.563-8.16z"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_247"
            fill="#263238"
            d="M371.518 306c.048.109.612 0 1.285.122.673.123 1.122.517 1.211.436.088-.082-.306-.68-1.122-.837-.816-.156-1.435.163-1.374.279z"
          />
          <path
            id="Vector_248"
            fill="#263238"
            d="M369.505 307.809c0 .115.558.129 1.102.462s.829.782.938.741c.109-.04-.047-.68-.727-1.094-.68-.415-1.347-.211-1.313-.109z"
          />
          <path
            id="Vector_249"
            fill="#263238"
            d="M369.186 311.127c.108 0 .061-.619-.436-1.115-.496-.496-1.101-.558-1.115-.449-.013.109.422.313.823.735.401.421.619.856.728.829z"
          />
          <path
            id="Vector_250"
            fill="#263238"
            d="M370.756 303.28c.082.082.531-.252 1.17-.435.639-.184 1.197-.163 1.217-.279.021-.115-.585-.34-1.36-.109-.775.231-1.115.721-1.027.823z"
          />
          <path
            id="Vector_251"
            fill="#263238"
            d="M369.866 300.914a2.213 2.213 0 001.128-.415 5.217 5.217 0 001.054-.789 7.17 7.17 0 00.524-.578c.099-.12.181-.252.245-.394a.506.506 0 00-.163-.599.633.633 0 00-.572-.081c-.145.05-.282.121-.408.21a5.015 5.015 0 00-.612.497 4.365 4.365 0 00-.795 1.061 2.035 2.035 0 00-.333 1.156c.061 0 .197-.415.584-1.007.223-.346.493-.66.803-.931.176-.164.367-.309.571-.436.218-.149.422-.224.524-.142.102.081.04.047 0 .136-.047.104-.108.2-.184.285a6.591 6.591 0 01-.469.558c-.29.298-.606.569-.945.809-.332.198-.65.419-.952.66z"
          />
          <path
            id="Vector_252"
            fill="#263238"
            d="M370.07 301.022a1.96 1.96 0 00-.096-1.203 4.056 4.056 0 00-.605-1.177 3.584 3.584 0 00-.537-.584.992.992 0 00-.925-.313.585.585 0 00-.36.489 1.584 1.584 0 000 .456c.053.267.147.523.279.762.215.4.525.741.904.992.68.422 1.156.388 1.156.347a9.174 9.174 0 01-.986-.578 2.76 2.76 0 01-.741-.931 2.402 2.402 0 01-.218-.68c-.054-.252 0-.476.068-.483a.685.685 0 01.531.224c.18.155.347.325.496.51.26.32.473.675.632 1.054.3.653.334 1.115.402 1.115z"
          />
          <path
            id="Vector_253"
            fill="#13CB6E"
            d="M388.545 292.4l4.617 11.472s-10.104 8.547-9.458 10.75l22.562-8.16-6.467-18.142-11.254 4.08z"
          />
          <path
            id="Vector_254"
            fill="#DADADA"
            d="M399.786 300.356a.986.986 0 011.019.823.953.953 0 01-.809 1.02 1.035 1.035 0 01-1.067-.877c0-.483.476-1.034.952-.966"
          />
          <g id="Group_7" opacity="0.6">
            <path
              id="Vector_255"
              fill="#fff"
              d="M406.266 306.449l-.721-1.809-21.345 8.507s-.843.795-.476 1.462l22.542-8.16z"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_256"
            fill="#263238"
            d="M392.754 303.878c.048.109.612-.04 1.286.116.673.156 1.128.517 1.21.435.082-.081-.306-.68-1.122-.829-.816-.15-1.435.183-1.374.278z"
          />
          <path
            id="Vector_257"
            fill="#263238"
            d="M390.742 305.714c0 .116.557.13 1.101.456.544.326.83.789.939.741.108-.047-.048-.68-.728-1.088-.68-.408-1.346-.217-1.312-.109z"
          />
          <path
            id="Vector_258"
            fill="#263238"
            d="M390.422 309.033c.109 0 .068-.619-.435-1.122-.503-.503-1.102-.558-1.115-.449-.014.109.421.32.822.735.402.414.626.863.728.836z"
          />
          <path
            id="Vector_259"
            fill="#263238"
            d="M391.993 301.145c.081.088.53-.252 1.169-.435.639-.184 1.197-.157 1.217-.272.021-.116-.584-.34-1.36-.116-.775.224-1.108.755-1.026.823z"
          />
          <path
            id="Vector_260"
            fill="#263238"
            d="M391.102 298.812c.408-.03.799-.173 1.129-.414a5.273 5.273 0 001.054-.789c.186-.182.361-.375.523-.578a1.47 1.47 0 00.245-.388.512.512 0 00-.163-.605.628.628 0 00-.571-.075c-.144.049-.279.12-.401.211-.22.145-.427.309-.619.49a4.364 4.364 0 00-.796 1.06 2.094 2.094 0 00-.333 1.163c.068 0 .197-.421.585-1.013a4.603 4.603 0 011.38-1.367c.211-.149.415-.224.517-.143.102.082.048.048 0 .136a.993.993 0 01-.184.286 6.38 6.38 0 01-.469.558c-.289.3-.606.573-.945.816-.329.2-.647.418-.952.652z"
          />
          <path
            id="Vector_261"
            fill="#263238"
            d="M391.306 298.921a1.941 1.941 0 00-.095-1.197 3.933 3.933 0 00-1.143-1.768.991.991 0 00-.924-.306.57.57 0 00-.361.483 1.831 1.831 0 000 .456c.054.266.148.523.279.761.215.401.526.742.904.993.68.429 1.156.395 1.156.354a8.818 8.818 0 01-.986-.585 2.846 2.846 0 01-.741-.932 2.349 2.349 0 01-.217-.68c-.048-.251 0-.482.068-.489a.677.677 0 01.53.231c.183.154.351.325.503.51.257.318.468.67.626 1.047.306.673.333 1.129.401 1.122z"
          />
          <path
            id="Vector_262"
            fill="#FFBE9D"
            d="M404.104 143.766l-2.156 17.68 3.352 8.16-13.26.469-2.72-6.365s-6.568-.741-6.8-4.944c-.163-2.72 4.169-22.256 4.169-22.256l17.415 7.256z"
          />
          <path
            id="Vector_263"
            fill="#263238"
            d="M399.84 164.771c1.564-1.061 2.353-1.768 3.04-3.529.686-1.762 1.128-3.611 1.91-5.332.782-1.72 2.04-3.434 2.816-5.242.775-1.809 1.224-3.951.435-5.767 0 0-.286-2.332-3.4-3.182 0 0-1.775-4.944-5.841-5.406-5.536-.633-5.345-2.258-8.956-3.638a3.504 3.504 0 00-4.685 1.686s-2.224 1.02-2.72 3.053v.055a4.848 4.848 0 002.257 5.311l.68.394c.667.312 1.25.778 1.7 1.36.599.911.47 2.108.204 3.162a8.596 8.596 0 00-.496 3.21c.048.367.258.809.632.788.374-.02.544-.612.68-1.033.388-1.231 2.204-1.843 3.142-.68a1.945 1.945 0 01.082 2.455c-.606.795-1.673 1.292-2.04 2.237-.68 1.584.979 3.4 1.53 4.488.19.378.418.735.68 1.067a15.686 15.686 0 004.624 4.026 4.247 4.247 0 004.338.218"
          />
          <path
            id="Vector_264"
            fill="#FFBE9D"
            d="M345.297 186.102c-2.325-.992-3.821-1.89-5.385-2.454-1.918-.68-2.666-1.122-2.51-1.476.204-.469.109-.299 3.781.476 0 0-5.236-1.149-3.903-1.795.585-.279 4.944.632 4.944.632-3.57-.415-4.849-1.129-4.76-1.666.129-.782 5.44.367 5.44.367a40.43 40.43 0 01-4.706-1.156c-.68-.36.184-.68.966-.782.782-.102 6.045.089 7.541.062a.88.88 0 00.965-.925c-.435-2.129.354-4.019.81-4.427a.674.674 0 01.766.02.675.675 0 01.26.721 8.003 8.003 0 000 2.197c.064.583.227 1.151.483 1.679.299.619.578 1.122.823 1.551l7.412 8.105-3.203 7.691-9.724-8.82z"
          />
          <path
            id="Vector_265"
            fill="#EB996E"
            d="M343.4 183.165a10.156 10.156 0 01-2.536-.429 9.827 9.827 0 01-2.462-.754c.86.028 1.713.17 2.536.421a9.58 9.58 0 012.462.762z"
          />
          <path
            id="Vector_266"
            fill="#EB996E"
            d="M343.828 181.784c-.915.01-1.828-.09-2.72-.299a11.02 11.02 0 01-2.672-.639c1.834.085 3.651.4 5.406.938h-.014z"
          />
          <path
            id="Vector_267"
            fill="#EB996E"
            d="M344.284 180.35a9.773 9.773 0 01-2.591-.245 9.86 9.86 0 01-2.536-.578c.87-.026 1.74.056 2.591.245.867.079 1.72.274 2.536.578z"
          />
          <path
            id="Vector_268"
            fill="#263238"
            d="M414.871 219.021l-31.726 4.765a15.432 15.432 0 00-10.632 6.806 15.446 15.446 0 00-1.886 12.804l14.607 50.247 16.902-4.458-8.924-45.319L426 241.667V220.11L414.871 218"
          />
          <path
            id="Vector_269"
            fill="#263238"
            d="M393.842 220.66l-31.694 4.76a15.446 15.446 0 00-10.622 6.8 15.437 15.437 0 00-1.897 12.75l16.735 53.373 15.116-5.685-9.282-47.178 49.035-3.284V220.66l-27.391-1.02"
          />
          <path
            id="Vector_270"
            fill="#13CB6E"
            d="M353.178 181.118c.68-.088 22.189 16.694 22.189 16.694l-13.743 5.508-13.933-13.818s-.891-7.507 5.487-8.384z"
          />
          <path
            id="Vector_271"
            fill="#13CB6E"
            d="M422.81 174.529c-3.59-5.202-9.696-7.004-11.811-7.48-.447-.1-.903-.15-1.36-.15h-18.333a12.84 12.84 0 00-8.901 3.536 44.055 44.055 0 00-7.045 9.085l-9.228 12.832-5.671 9.52 1.156 1.455a9.517 9.517 0 0011.39 2.903c1.87-.877 3.237-2.502 4.509-4.372 2.318-3.4 4.76-7.086 6.337-9.894l9.248 26.445-4.943 3.149 20.848 8.425 21.706-9.282-5.998-21.366s9.52.918 10.384-2.815c.864-3.733-7.895-15.64-12.288-21.991z"
          />
          <path
            id="Vector_272"
            fill="#FAFAFA"
            d="M420.662 184.219a86.876 86.876 0 014.338 8.01c.177.354.68.041.53-.313a68.175 68.175 0 00-4.515-7.901c-.136-.218-.483 0-.347.204h-.006z"
          />
          <path
            id="Vector_273"
            fill="#FAFAFA"
            d="M425 182.675a86.839 86.839 0 014.332 8.011c.176.353.68.04.53-.313a67.815 67.815 0 00-4.515-7.902c-.136-.217-.483 0-.347.204z"
          />
          <path
            id="Vector_274"
            fill="#FAFAFA"
            d="M416.493 184.831c.918 3.549 1.639 7.153 2.224 10.771.081.51.857.292.768-.211a71.138 71.138 0 00-2.645-10.655.16.16 0 00-.031-.059.17.17 0 00-.05-.043.169.169 0 00-.231.068.166.166 0 00-.014.129h-.021z"
          />
          <path
            id="Vector_275"
            fill="#FAFAFA"
            d="M406.674 185.844a43.017 43.017 0 011.788 10.676c0 .503.803.51.782 0a35.54 35.54 0 00-2.189-10.785c-.082-.238-.463-.143-.381.109z"
          />
          <path
            id="Vector_276"
            fill="#FAFAFA"
            d="M414.705 194.48a47.921 47.921 0 01-2.373-10.064c-.048-.469-.748-.49-.742 0a20.213 20.213 0 002.72 10.2c.123.19.463.068.388-.17l.007.034z"
          />
          <path
            id="Vector_277"
            fill="#FAFAFA"
            d="M402.288 186.13a45.829 45.829 0 001.482 10.159c.123.462.796.265.68-.197a383.033 383.033 0 01-1.802-10.017c0-.217-.394-.163-.387.055h.027z"
          />
          <path
            id="Vector_278"
            fill="#FAFAFA"
            d="M397.025 186.728c.911 3.726 1.591 7.48 2.264 11.24.061.354.68.279.633-.088a60.568 60.568 0 00-2.625-11.227.14.14 0 00-.07-.07.138.138 0 00-.098-.009.142.142 0 00-.08.058.137.137 0 00-.024.096z"
          />
          <path
            id="Vector_279"
            fill="#FAFAFA"
            d="M392.21 187.598c-.031 3.494.35 6.98 1.136 10.384.095.367.605.204.558-.156a95.548 95.548 0 01-1.245-10.2.224.224 0 10-.449 0v-.028z"
          />
          <path
            id="Vector_280"
            fill="#FAFAFA"
            d="M376.829 197.098c2.883-2.72 5.44-6.12 7.806-9.262.211-.278-.258-.639-.489-.374-2.591 2.965-5.44 6.12-7.48 9.486-.075.123.095.245.197.15h-.034z"
          />
          <path
            id="Vector_281"
            fill="#FAFAFA"
            d="M373.456 194.072a32.74 32.74 0 006.664-8.228c.211-.354-.32-.68-.544-.32-1.992 2.911-4.216 5.549-6.385 8.303a.172.172 0 00-.004.242.17.17 0 00.12.051.168.168 0 00.122-.048h.027z"
          />
          <path
            id="Vector_282"
            fill="#FAFAFA"
            d="M375.489 184.185c-1.917 2.149-3.903 4.257-5.712 6.494-.245.299.163.727.429.435 1.924-2.156 3.692-4.454 5.514-6.698.13-.163-.088-.394-.231-.231z"
          />
          <path
            id="Vector_283"
            fill="#FAFAFA"
            d="M387.321 188.115c.816 3.448 1.299 6.95 2.04 10.411.075.326.633.265.599-.082a78.51 78.51 0 00-2.197-10.458.238.238 0 00-.423-.039.236.236 0 00-.032.168h.013z"
          />

          <path
            id="Vector_285"
            fill="#263238"
            d="M403.355 217.675a3.62 3.62 0 01-1.189.462c-.997.273-2.021.433-3.054.476a13.589 13.589 0 01-3.08-.204 3.75 3.75 0 01-1.231-.353c0-.109 1.925.319 4.298.204a41.458 41.458 0 004.256-.585z"
          />
          <path
            id="Vector_286"
            fill="#455A64"
            d="M365.622 246.357l-8.336 69.952h3.604l8.853-69.952h-4.121z"
          />
          <path
            id="Vector_287"
            fill="#455A64"
            d="M398.854 246.357l7.657 69.952h3.93l-7.466-69.952h-4.121z"
          />
          <path
            id="Vector_288"
            fill="#455A64"
            d="M440.069 246.357l6.385 69.952h4.379l-7.283-69.952h-5.283"
          />
          <path
            id="Vector_289"
            fill="#455A64"
            d="M418.771 186.259h30.906a4.812 4.812 0 014.613 3.45c.194.661.245 1.355.147 2.037l-7.317 51.062a4.137 4.137 0 01-4.08 3.549h-40.052l15.783-60.098z"
          />
          <path
            id="Vector_290"
            fill="#455A64"
            d="M361.508 246.357c.075-.775 0-4.637 0-4.637l38.325.68a5.889 5.889 0 005.916-4.93l7.637-46.669a5.438 5.438 0 015.385-4.576l-8.33 52.543a8.955 8.955 0 01-8.84 7.555l-40.093.034z"
          />
          <path
            id="Vector_291"
            fill="#263238"
            d="M418.771 186.259a.407.407 0 010 .163l-.054.469c-.061.442-.15 1.047-.252 1.809-.245 1.598-.585 3.862-.999 6.644-.884 5.63-2.095 13.362-3.4 21.902-.68 4.271-1.36 8.344-1.925 12.05-.299 1.85-.585 3.602-.857 5.256a35.65 35.65 0 01-.931 4.556 12.394 12.394 0 01-1.578 3.509 8.808 8.808 0 01-1.979 2.129 6.663 6.663 0 01-1.598.897c-.197.081-.402.14-.612.177a8.049 8.049 0 002.129-1.183 10.546 10.546 0 003.4-5.597c.387-1.492.682-3.007.884-4.535.251-1.653.53-3.4.816-5.263.585-3.706 1.231-7.78 1.904-12.057 1.36-8.527 2.625-16.252 3.529-21.882.469-2.775.843-5.025 1.115-6.623.129-.762.238-1.36.32-1.802.034-.184.061-.34.088-.463a.813.813 0 010-.156z"
          />
          <path
            id="Vector_292"
            fill="#263238"
            d="M420.485 186.599a15.004 15.004 0 01-1.863-2.639 14.187 14.187 0 01-1.558-2.828 15.2 15.2 0 011.857 2.638c.625.882 1.15 1.83 1.564 2.829z"
          />
          <path
            id="Vector_293"
            fill="#263238"
            d="M362.161 197.88a27.493 27.493 0 012.394-3.733 29.026 29.026 0 012.686-3.529 28.077 28.077 0 01-2.394 3.733 29.026 29.026 0 01-2.686 3.529z"
          />
          <path
            id="Vector_294"
            fill="#263238"
            d="M388.015 185.538a5.045 5.045 0 01-.415.782c-.32.558-.728 1.258-1.217 2.088a216.192 216.192 0 01-4.203 6.8 161.966 161.966 0 01-4.481 6.602c-.598.83-1.095 1.49-1.441 1.938a4.14 4.14 0 01-.572.68 4.25 4.25 0 01.456-.761l1.36-2.006a359.65 359.65 0 004.4-6.651l4.284-6.718c.55-.864.992-1.557 1.312-2.04.143-.258.317-.497.517-.714z"
          />
        </g>
        <g id="bun-woman">
          <path
            id="Vector_295"
            fill="#263238"
            d="M202.715 106.644c-1.408-2.04-.537-5.195 1.564-6.548a6.331 6.331 0 017.031.503c1.999 1.503 2.557 4.121 2.23 6.365-.244 1.666-2.856 5.066-5.344 3.57"
          />
          <path
            id="Vector_296"
            fill="#263238"
            d="M187.721 120.788c-5.529-10.71 6.045-16.802 12.6-15.3 6.161 1.415 8.901 4.312 10.105 8.324a20.117 20.117 0 010 12.369c-.68 1.951-2.04 3.957-4.08 4.304"
          />
          <path
            id="Vector_297"
            fill="#FFBE9D"
            d="M204.503 111.391a2.04 2.04 0 011.952 1.999l.217 27.941c.123 3.298-4.596 5.399-8.126 5.501-3.563.102-3.767-1.638-3.978-4.76-.346-5.229-.442-7.194-.442-7.167 0 .027-4.535-.523-5.759-6.344-.612-2.897-.68-7.691-.68-11.615.047-3.536 2.162-7.969 5.691-7.772l11.125 2.217z"
          />
          <path
            id="Vector_298"
            fill="#263238"
            d="M189.264 120.51a.76.76 0 00.762.727.717.717 0 00.684-.44.713.713 0 00.057-.281.749.749 0 00-.761-.72.715.715 0 00-.742.714z"
          />
          <path
            id="Vector_299"
            fill="#263238"
            d="M188.877 119.374c.095.095.646-.354 1.455-.394.809-.041 1.428.34 1.51.238.081-.102-.062-.225-.334-.395a2.04 2.04 0 00-1.21-.313 1.997 1.997 0 00-1.156.442c-.238.204-.313.381-.265.422z"
          />
          <path
            id="Vector_300"
            fill="#263238"
            d="M196.887 120.129a.764.764 0 00.762.727.716.716 0 00.741-.72.757.757 0 00-.762-.721.71.71 0 00-.684.434.712.712 0 00-.057.28z"
          />
          <path
            id="Vector_301"
            fill="#263238"
            d="M196.846 119.129c.102.095.646-.353 1.462-.394.816-.041 1.422.34 1.503.231.082-.109-.061-.218-.333-.394a2.087 2.087 0 00-1.21-.306 2.04 2.04 0 00-1.156.435c-.238.204-.313.387-.266.428z"
          />
          <path
            id="Vector_302"
            fill="#263238"
            d="M194.208 125.283a5.243 5.243 0 00-1.36-.17c-.211 0-.408-.041-.449-.183a1.068 1.068 0 01.109-.619l.53-1.605a28.29 28.29 0 001.17-4.189 26.567 26.567 0 00-1.503 4.08l-.51 1.612a1.22 1.22 0 00-.068.816.518.518 0 00.361.285c.115.027.234.039.353.034.457.04.916.019 1.367-.061z"
          />
          <path
            id="Vector_303"
            fill="#EB996E"
            d="M194.126 134.898a14.915 14.915 0 007.759-2.488s-1.707 4.127-7.609 3.869l-.15-1.381z"
          />
          <path
            id="Vector_304"
            fill="#EB996E"
            d="M194.65 127.221a1.442 1.442 0 011.278-.605 1.361 1.361 0 01.946.442.86.86 0 01.115.966.984.984 0 01-1.054.319 3.027 3.027 0 01-1.061-.564.803.803 0 01-.244-.231.26.26 0 010-.3"
          />
          <path
            id="Vector_305"
            fill="#263238"
            d="M196.622 125.725c-.136 0-.095.877-.816 1.544-.721.666-1.659.612-1.659.734 0 .123.217.157.612.15a2.178 2.178 0 001.36-.565c.367-.331.591-.792.625-1.285.041-.374-.068-.585-.122-.578z"
          />
          <path
            id="Vector_306"
            fill="#263238"
            d="M196.411 117.728c.089.218.898.075 1.85.143.952.068 1.741.286 1.856.082.055-.095-.095-.299-.415-.497a3.076 3.076 0 00-1.36-.408 3.13 3.13 0 00-1.434.238c-.388.157-.538.354-.497.442z"
          />
          <path
            id="Vector_307"
            fill="#263238"
            d="M189.04 117.014c.15.177.68-.04 1.36-.081s1.244.061 1.36-.136c.054-.095-.048-.279-.299-.442a1.99 1.99 0 00-1.136-.245 1.908 1.908 0 00-1.074.428c-.211.198-.259.422-.211.476z"
          />
          <path
            id="Vector_308"
            fill="#263238"
            d="M189.38 109.541a5.679 5.679 0 002.38 5.44 10.962 10.962 0 003.346 1.313l2.237.612c1.632.526 3.331.822 5.045.877-.088 1.917-.312 3.209.721 4.828.925 1.455 2.271 2.482 4.012 2.04a57.22 57.22 0 00.68-11.472 5.381 5.381 0 00-.578-2.863c-.884-1.36-2.72-1.632-4.372-1.761l-6.861-.537a13.886 13.886 0 00-3.36 0 4.623 4.623 0 00-2.896 1.564"
          />
          <path
            id="Vector_309"
            fill="#455A64"
            d="M211.48 117.824h-.258a1.867 1.867 0 01-.728-.164 3 3 0 01-.959-.68 15.366 15.366 0 01-.979-1.196 5.54 5.54 0 00-1.36-1.245 7.282 7.282 0 00-1.911-.884 16.32 16.32 0 00-4.835-.53 22.014 22.014 0 01-4.916-.34 7.034 7.034 0 01-3.543-1.884 5.403 5.403 0 01-.931-1.251 4.13 4.13 0 01-.395-1.108 2.986 2.986 0 01-.047-.993c.026.326.071.651.136.972.091.373.233.733.421 1.068.255.439.571.84.939 1.19a6.906 6.906 0 003.468 1.795c1.607.279 3.239.384 4.868.313a16.113 16.113 0 014.896.564c.695.21 1.355.522 1.959.925.522.357.982.798 1.36 1.306.347.455.639.884.945 1.21.259.278.565.508.904.68.301.151.63.236.966.252z"
          />
          <path
            id="Vector_310"
            fill="#455A64"
            d="M210.548 114.24a1.84 1.84 0 00-.122-.83 3.406 3.406 0 00-1.754-1.455 11.565 11.565 0 00-3.346-.897 29.928 29.928 0 00-4.277 0 19.925 19.925 0 01-4.318-.15 8.635 8.635 0 01-3.285-1.278 5.627 5.627 0 01-1.679-1.687 5.135 5.135 0 01-.293-.578 1.036 1.036 0 01-.074-.211s.136.279.448.742a5.841 5.841 0 001.687 1.598 8.794 8.794 0 003.23 1.203c1.415.189 2.846.233 4.27.129a28.72 28.72 0 014.311 0c1.177.145 2.323.471 3.4.966a3.392 3.392 0 011.768 1.564c.087.215.118.45.089.68a.6.6 0 01-.055.204z"
          />
          <path
            id="Vector_311"
            fill="#455A64"
            d="M213.119 102.966a.87.87 0 01.095.394c.019.37-.046.74-.19 1.081a6.13 6.13 0 01-2.455 2.605c-.572.347-1.2.591-1.857.72a5.533 5.533 0 01-1.625.048 7.815 7.815 0 01-1.074-.19 1.457 1.457 0 01-.374-.136c0-.041.557.108 1.462.163a5.874 5.874 0 001.571-.102 5.597 5.597 0 001.774-.68 6.343 6.343 0 002.421-2.475 2.9 2.9 0 00.252-1.428z"
          />
          <path
            id="Vector_312"
            fill="#455A64"
            d="M210.997 100.375c.022.13.022.264 0 .394-.035.357-.103.71-.204 1.054a7.033 7.033 0 01-1.768 3.047 7.14 7.14 0 01-3.033 1.802 5.82 5.82 0 01-1.047.204c-.13.023-.264.023-.394 0 0-.041.551-.089 1.394-.374a7.356 7.356 0 004.692-4.76c.279-.81.319-1.367.36-1.367z"
          />
          <path
            id="Vector_313"
            fill="#455A64"
            d="M210.412 125.8a2.232 2.232 0 010-.306v-.891a9.991 9.991 0 00-.598-3.182 6.265 6.265 0 00-1.142-1.897 5.23 5.23 0 00-2.095-1.408c-1.7-.639-3.747-.537-5.848-.626-2-.045-3.979-.424-5.855-1.122a9.745 9.745 0 01-3.964-2.801 7.375 7.375 0 01-1.523-2.897 5.299 5.299 0 01-.157-.884 1.365 1.365 0 010-.313s.041.429.259 1.17a7.592 7.592 0 001.564 2.815 9.81 9.81 0 003.917 2.72 17.988 17.988 0 005.78 1.081c2.087.095 4.161 0 5.916.68a5.227 5.227 0 012.162 1.476c.503.58.895 1.249 1.156 1.972a9.513 9.513 0 01.537 3.237v.89c0 .211-.095.286-.109.286z"
          />
          <path
            id="Vector_314"
            fill="#13CB6E"
            d="M209.08 110.69a10.22 10.22 0 01-.844-.897 14.074 14.074 0 00-4.814-3.332c-.388-.133-.767-.29-1.136-.469.412.049.814.155 1.197.312a11.68 11.68 0 014.883 3.4c.279.297.519.628.714.986z"
          />
          <path
            id="Vector_315"
            fill="#FFBE9D"
            d="M205.829 121.128c.082-.04 3.074-1.543 3.285 1.966.21 3.508-3.36 2.998-3.4 2.903-.041-.095.115-4.869.115-4.869z"
          />
          <path
            id="Vector_316"
            fill="#EB996E"
            d="M206.72 124.596s.068.041.17.082c.148.06.314.06.462 0a1.515 1.515 0 00.619-1.36 2.042 2.042 0 00-.224-.87.684.684 0 00-.49-.442.318.318 0 00-.353.19c-.041.102 0 .17-.034.177-.034.007-.075-.055-.055-.197a.357.357 0 01.123-.232.463.463 0 01.333-.108.859.859 0 01.68.503c.167.295.26.626.272.965a1.59 1.59 0 01-.803 1.571.685.685 0 01-.564-.054c-.136-.136-.136-.218-.136-.225z"
          />
          <path
            id="Vector_317"
            fill="#FFBE9D"
            d="M154.136 152.558s-2.36-4.638-2.958-5.086c-.599-.449-5.876-2.285-6.324-2.136-.449.15-6.8 1.36-6.046 2.081.755.721 5.168-.143 5.168-.143l.599.286s-3.822.68-4.624 1.149c-.803.469-.469 1.36.231 1.265.7-.095 4.026-.782 4.223-.68.197.102-3.604.952-4.012 1.204-.551.333-.592 1.319.109 1.264.408-.04 3.998-1.026 3.998-1.026-1.058.41-2.093.878-3.101 1.4a.528.528 0 00-.115.68.524.524 0 00.659.204c.429-.095 2.428-1.094 2.999-1.224 0 0 .53.13 1.034 1.204.503 1.074 4.012 3.74 4.012 3.74l4.148-4.182z"
          />
          <path
            id="Vector_318"
            fill="#FFBE9D"
            d="M152.68 152.646a1.26 1.26 0 01-1.475 0c-.463-.265-.816-.68-1.251-.979-1.313-.891-3.169-.639-4.434-1.591-.177-.136-.354-.354-.258-.558.095-.204.333-.21.537-.204l5.243.041"
          />
          <path
            id="Vector_319"
            fill="#EB996E"
            d="M151.042 149.376a2.153 2.153 0 00-.565-.062c-.367 0-.897-.047-1.55-.061l-2.292-.054h-.68a1.03 1.03 0 00-.768.17.438.438 0 00-.082.489.9.9 0 00.272.334c.21.158.439.29.68.394.887.312 1.797.558 2.72.734.383.104.747.264 1.081.476.306.198.565.449.83.68a1.855 1.855 0 001.482.497c.388-.082.51-.293.497-.293-.158.09-.331.15-.51.177a1.778 1.778 0 01-1.36-.544 10.72 10.72 0 00-.823-.68 4.345 4.345 0 00-1.149-.53 22.683 22.683 0 01-2.625-.823 3.11 3.11 0 01-.605-.354c-.17-.129-.259-.312-.163-.387.095-.075.326-.095.55-.089h2.972c.68 0 1.183 0 1.55-.034.18.007.361-.007.538-.04z"
          />
          <path
            id="Vector_320"
            fill="#EB996E"
            d="M138.822 147.438c.363.178.759.28 1.163.299.435.054.875.065 1.312.034.483-.061 1.013-.15 1.571-.231l1.183-.198h-.068c.884.344 1.793.617 2.72.816a16.312 16.312 0 00-2.659-1.088h-.04l-1.177.177-1.557.252a7.422 7.422 0 01-1.265 0 10.628 10.628 0 00-1.183-.061z"
          />
          <path
            id="Vector_321"
            fill="#13CB6E"
            d="M172.645 286.11l5.712 10.608s-8.84 9.37-7.997 11.431l20.965-10.309-8.221-16.891-10.459 5.161z"
          />
          <path
            id="Vector_322"
            fill="#DADADA"
            d="M184.396 292.611a.957.957 0 011.074.68.91.91 0 01-.68 1.067 1.013 1.013 0 01-1.136-.727.945.945 0 01.823-1.034"
          />
          <g id="Group_9" opacity="0.6">
            <path
              id="Vector_323"
              fill="#fff"
              d="M191.325 297.84l-.925-1.7-19.72 10.54s-.734.864-.306 1.469l20.951-10.309z"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_324"
            fill="#263238"
            d="M177.97 296.779c.054.102.584-.102 1.251 0 .666.102 1.149.381 1.224.293.075-.089-.374-.599-1.183-.68-.81-.082-1.36.306-1.292.387z"
          />
          <path
            id="Vector_325"
            fill="#263238"
            d="M176.215 298.772c0 .115.558.068 1.115.326.558.258.891.68.993.619.102-.061-.122-.68-.829-.979-.708-.3-1.32-.062-1.279.034z"
          />
          <path
            id="Vector_326"
            fill="#263238"
            d="M176.27 302.022c.102 0 0-.605-.551-1.04-.551-.436-1.129-.415-1.129-.306 0 .108.442.258.884.618.442.361.687.769.796.728z"
          />
          <path
            id="Vector_327"
            fill="#263238"
            d="M176.936 294.216c.088.074.49-.3 1.081-.544.592-.245 1.143-.286 1.149-.402.007-.115-.598-.265-1.298.034-.701.3-1.027.857-.932.912z"
          />
          <path
            id="Vector_328"
            fill="#263238"
            d="M175.821 292.06a2.236 2.236 0 001.047-.524 5.532 5.532 0 001.374-1.496c.085-.125.152-.263.197-.408a.498.498 0 00-.218-.564.615.615 0 00-.564 0 1.418 1.418 0 00-.367.245 3.99 3.99 0 00-.544.551c-.286.33-.516.704-.68 1.108a1.944 1.944 0 00-.198 1.163c.062 0 .143-.429.449-1.047.184-.357.412-.688.68-.986.155-.177.326-.338.51-.483.191-.17.381-.265.49-.197.109.068.047.047.034.129a1.133 1.133 0 01-.15.299 6.47 6.47 0 01-.394.592 6.736 6.736 0 01-.823.89c-.49.449-.87.674-.843.728z"
          />
          <path
            id="Vector_329"
            fill="#263238"
            d="M176.025 292.135a1.806 1.806 0 00-.225-1.149 3.824 3.824 0 00-.68-1.075 3.64 3.64 0 00-.584-.51.982.982 0 00-.932-.197.55.55 0 00-.292.51c.006.148.033.295.081.435.081.243.198.472.347.68.253.364.592.661.986.864.342.221.757.299 1.156.217-.346-.135-.684-.29-1.013-.462a2.548 2.548 0 01-.823-.823 2.49 2.49 0 01-.279-.612c-.075-.238-.081-.462 0-.483a.69.69 0 01.537.164 4.08 4.08 0 011.258 1.394c.32.632.402 1.06.463 1.047z"
          />
          <path
            id="Vector_330"
            fill="#13CB6E"
            d="M178.242 295.297l-2.176 11.845s-12.771 1.775-13.39 3.924l22.828 5.039 4.141-18.326-11.403-2.482z"
          />
          <path
            id="Vector_331"
            fill="#DADADA"
            d="M183.342 307.714a.943.943 0 01.408 1.203.909.909 0 01-.511.45.907.907 0 01-.679-.028 1.015 1.015 0 01-.429-1.279.95.95 0 011.292-.299"
          />
          <g id="Group_10" opacity="0.6">
            <path
              id="Vector_332"
              fill="#fff"
              d="M185.504 316.105l.34-1.863-22.012-4.162s-1.108.218-1.156.959l22.828 5.066z"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_333"
            fill="#263238"
            d="M175.726 306.945c0 .116.523.286.992.769.47.482.68 1.013.769.992.088-.02.088-.68-.49-1.278s-1.278-.592-1.271-.483z"
          />
          <path
            id="Vector_334"
            fill="#263238"
            d="M173.108 307.408c-.048.102.394.401.68.952.285.55.278 1.081.387 1.101.109.021.32-.598-.034-1.278-.353-.68-1.006-.877-1.033-.775z"
          />
          <path
            id="Vector_335"
            fill="#263238"
            d="M171.115 309.971c.102.048.374-.476.225-1.149-.15-.673-.619-1.027-.68-.945-.062.082.183.476.299 1.034.115.557.047 1.026.156 1.06z"
          />
          <path
            id="Vector_336"
            fill="#263238"
            d="M176.521 304.3c0 .116.565.075 1.19.252.626.176 1.068.489 1.143.408.074-.082-.306-.585-1.041-.789-.734-.204-1.333.034-1.292.129z"
          />
          <path
            id="Vector_337"
            fill="#263238"
            d="M176.997 301.92c.354.184.751.268 1.149.245a5.178 5.178 0 002.006-.306c.146-.042.283-.106.408-.191a.504.504 0 00.184-.584.672.672 0 00-.435-.361 1.461 1.461 0 00-.442-.034c-.256.002-.511.03-.762.082-.425.09-.833.246-1.21.462-.639.374-.911.748-.877.782.034.034.38-.245 1.006-.53a4.314 4.314 0 011.85-.408c.251 0 .462.034.496.156.034.123 0 .061-.054.123-.09.061-.189.107-.293.136a5.939 5.939 0 01-.68.21 6.164 6.164 0 01-1.183.218 7.526 7.526 0 00-1.163 0z"
          />
          <path
            id="Vector_338"
            fill="#263238"
            d="M177.113 302.11c.034 0 .34-.326.544-1.04.111-.419.153-.853.122-1.285a3.797 3.797 0 00-.143-.755.963.963 0 00-.598-.741.57.57 0 00-.551.217 1.745 1.745 0 00-.211.395 2.665 2.665 0 00.062 2.053c.312.68.748.925.768.891a8.937 8.937 0 01-.51-.993 2.794 2.794 0 01-.122-1.156c.02-.233.075-.462.163-.68.088-.231.224-.408.313-.367.088.041.265.252.312.463.072.221.122.449.15.68.059.394.059.795 0 1.19-.15.707-.36 1.101-.299 1.128z"
          />
          <path
            id="Vector_339"
            fill="#263238"
            d="M188.707 186.238l-35.027 45.159a16.33 16.33 0 00-2.564 15.307l25.371 51.816 8.514-14.477-14.545-40.916 18.972-22.807 8.452-34.959-9.173.877z"
          />
          <path
            id="Vector_340"
            fill="#263238"
            d="M175.073 305.374l15.701-109.602.782-9.806 19.244-1.006s6.732 10.486 7.052 16.905c.319 6.419-17.524 51.319-17.524 51.319l-2.564 17.918L187 309.019l-11.927-3.645z"
          />
          <path
            id="Vector_341"
            fill="#455A64"
            d="M191.393 188.006c.095 0-3.475 26.303-7.983 58.705-4.509 32.402-8.242 58.677-8.337 58.663-.095-.013 3.481-26.295 7.983-58.711 4.502-32.415 8.242-58.67 8.337-58.657z"
          />
          <path
            id="Vector_342"
            fill="#455A64"
            d="M170.347 243.175c-.041.047-.429-.293-1.149-.68a7.28 7.28 0 00-6.12-.238c-.742.319-1.156.625-1.197.578.31-.333.691-.591 1.115-.755a6.634 6.634 0 016.324.245c.404.199.755.49 1.027.85z"
          />
          <path
            id="Vector_343"
            fill="#455A64"
            d="M211.949 191.658s-.136.109-.421.245c-.397.19-.811.338-1.238.442a8.686 8.686 0 01-1.945.272 10.08 10.08 0 01-2.4-.238 10.388 10.388 0 01-2.258-.83 9.129 9.129 0 01-1.632-1.095 6.773 6.773 0 01-.925-.945c-.19-.245-.285-.387-.265-.401.021-.014.483.49 1.36 1.149.509.385 1.053.72 1.625 1 .701.334 1.435.593 2.19.775.759.168 1.534.255 2.312.258.635 0 1.268-.063 1.89-.19 1.054-.218 1.68-.503 1.707-.442z"
          />
          <path
            id="Vector_344"
            fill="#455A64"
            d="M155.72 256.088c-.232-.34-.435-.7-.605-1.074a80.874 80.874 0 01-1.53-2.992 43.898 43.898 0 01-1.993-4.584 24.856 24.856 0 01-.85-2.828 15.779 15.779 0 01-.435-3.156 13.234 13.234 0 01.279-3.182c.223-.967.528-1.914.911-2.829a24.685 24.685 0 012.469-4.345c.816-1.17 1.536-2.04 2.04-2.679.244-.306.455-.537.591-.68.063-.086.139-.161.225-.225a9.114 9.114 0 01-.714 1.007c-.47.632-1.156 1.55-1.938 2.72a25.607 25.607 0 00-2.374 4.338 17.708 17.708 0 00-.877 2.775 13.166 13.166 0 00-.265 3.094c.038 1.04.179 2.074.422 3.087.213.95.485 1.886.816 2.801.618 1.762 1.319 3.298 1.91 4.584l1.428 3.039.374.837c.051.092.09.19.116.292z"
          />
          <path
            id="Vector_345"
            fill="#13CB6E"
            d="M184.559 145.663l-13.022 14.987-16.885-9.343-5.902 5.909 21.923 15.021 9.779-.149 8.343-16.45-4.236-9.975z"
          />
          <path
            id="Vector_346"
            fill="#13CB6E"
            d="M179.799 156.726l7.242 30.526 24.317-2.088 3.128-24.004L221 150.906s-1.612-9.636-12.301-9.711l-1.734-4.08-12.818 1.075-1.523 3.733s-10.547.755-10.302 10.329l-2.523 4.474z"
          />
          <g id="Group_11" opacity="0.3">
            <path
              id="Vector_347"
              fill="#000"
              d="M185.946 182.668a12.726 12.726 0 0010.88-2.087c.416-.382.91-.667 1.448-.837a3.539 3.539 0 011.741.252 32.145 32.145 0 008.065 1.442 5.673 5.673 0 002.802-.374 2.191 2.191 0 001.298-2.306 74.828 74.828 0 00-12.648-3.4c-2.237-.394-4.549-.68-6.766-.183-2.216.496-4.27 1.815-6.215 3.101a2.715 2.715 0 00-1.244 1.278c-.381 1.122.755 2.298.523 3.461"
              opacity="0.3"
            />
          </g>
          <path
            id="Vector_348"
            fill="#13CB6E"
            d="M204.184 150.518c.68.619 9.724 16.123 9.724 16.123l-14.444 3.155-.496 7.12 14.205 2.04 6.385.68a8.209 8.209 0 008.419-11.397l-7.378-18.782-16.415 1.061z"
          />
          <path
            id="Vector_349"
            fill="#263238"
            d="M217.886 166.6c0 .095-4.08.898-9.18 1.782s-9.228 1.523-9.242 1.428c-.013-.096 4.08-.891 9.18-1.782s9.228-1.537 9.242-1.428z"
          />
          <path
            id="Vector_350"
            fill="#263238"
            d="M214.465 167.164a4.485 4.485 0 01-.387-.775c-.245-.571-.558-1.299-.932-2.155a492.813 492.813 0 01-2.951-7.161 534.758 534.758 0 01-1.585-3.964 7.194 7.194 0 01-.632-3.461 3.511 3.511 0 011.04-2.122c.161-.15.339-.28.531-.388a.684.684 0 01.211-.081 8.165 8.165 0 00-.64.564 3.53 3.53 0 00-.89 2.04 7.144 7.144 0 00.68 3.312c.503 1.217 1.04 2.55 1.611 3.944a506.567 506.567 0 012.856 7.201c.333.877.612 1.612.83 2.203.122.269.209.552.258.843z"
          />
          <path
            id="Vector_351"
            fill="#263238"
            d="M221.877 179.704a3.912 3.912 0 01-.911.034c-.585 0-1.435 0-2.475-.075-2.095-.109-4.971-.367-8.16-.809-3.189-.442-6.011-.932-8.065-1.286l-2.441-.442a4.008 4.008 0 01-.884-.21c.305-.002.61.023.911.074.578.068 1.414.177 2.448.327 2.04.299 4.923.748 8.078 1.183 3.156.435 6.018.741 8.099.911l2.469.191c.313.003.625.037.931.102z"
          />
          <path
            id="Vector_352"
            fill="#263238"
            d="M184.198 170.088a8.86 8.86 0 00-.38-2.577c-.218-.782-.578-1.686-.85-2.754a5.443 5.443 0 01-.191-1.727c.05-.622.18-1.234.388-1.822a28.902 28.902 0 013.067-5.631l1.047-1.625a3.71 3.71 0 01.401-.592 2.224 2.224 0 01-.279.68c-.211.408-.537.986-.959 1.687a34.962 34.962 0 00-2.944 5.617 6.992 6.992 0 00-.374 1.727 4.99 4.99 0 00.163 1.632c.238 1.04.578 1.958.775 2.761.173.631.255 1.283.245 1.938a2.95 2.95 0 01-.054.53c-.027.102-.041.163-.055.156z"
          />
          <path
            id="Vector_353"
            fill="#263238"
            d="M196.098 142.467a6.789 6.789 0 01-.047-2.217 6.95 6.95 0 01.306-2.196c.13.732.146 1.48.047 2.216a7.291 7.291 0 01-.306 2.197z"
          />
          <path
            id="Vector_354"
            fill="#263238"
            d="M200.852 142.038a13.472 13.472 0 01-.68-4.331c.29.678.466 1.399.523 2.135.168.719.22 1.461.157 2.196z"
          />
          <path
            id="Vector_355"
            fill="#263238"
            d="M204.959 141.25a9.827 9.827 0 01-1.047-3.57 9.855 9.855 0 011.047 3.57z"
          />
          <g id="Group_12" opacity="0.3">
            <path
              id="Vector_356"
              fill="#000"
              d="M208.366 152.646c.074 4.652.904 8.507.979 13.159-.026.451.051.902.224 1.319.524 1.006 1.707.68 2.836.639a10.83 10.83 0 001.727-.367 1.512 1.512 0 00-.143-1.027c-1.877-4.835-3.747-8.895-5.623-13.723z"
              opacity="0.3"
            />
          </g>
          <path
            id="Vector_357"
            fill="#263238"
            d="M157.032 161.854l6.984 6.732 11.274 10.866 19.394-.966-15.701-17.727-21.951 1.095z"
          />
          <path
            id="Vector_358"
            fill="#DADADA"
            d="M160.092 161.65s-5.066 2.25.096 6.12c5.161 3.869 12.24 5.338 13.3 8.16a4.757 4.757 0 01-.68 4.76l14.858-.68s4.414-3.808 1.122-7.202c-3.291-3.393-11.927-8.248-12.926-9.608-1-1.36 1.407-2.387 1.407-2.387l-17.177.837z"
          />
          <path
            id="Vector_359"
            fill="#455A64"
            d="M164.798 161.432l-.585-.687 8.017-.496v.829l-7.432.354z"
          />
          <path
            id="Vector_360"
            fill="#FAFAFA"
            d="M174.556 163.696a9.657 9.657 0 01-1.992.306c-1.238.13-2.952.272-4.849.381-1.897.109-3.617.143-4.869.116a8.678 8.678 0 01-2.04-.17 19.031 19.031 0 012.04-.082c1.238 0 2.958-.115 4.849-.217l4.848-.279a14.244 14.244 0 012.013-.055z"
          />
          <path
            id="Vector_361"
            fill="#FAFAFA"
            d="M176.12 165.92a61.07 61.07 0 01-6.494.483c-2.165.16-4.338.205-6.508.136a57.595 57.595 0 016.494-.49 60.392 60.392 0 016.508-.129z"
          />
          <path
            id="Vector_362"
            fill="#FAFAFA"
            d="M179.574 167.804a9.405 9.405 0 01-1.863.326c-1.156.156-2.72.326-4.529.469a83.96 83.96 0 01-4.549.238c-.63.05-1.263.037-1.89-.041a10.475 10.475 0 011.877-.21l4.535-.34 4.529-.361c.627-.081 1.259-.109 1.89-.081z"
          />
          <path
            id="Vector_363"
            fill="#FAFAFA"
            d="M181.907 170.347a45.05 45.05 0 01-5.556.619 44.678 44.678 0 01-5.583.265 44.478 44.478 0 015.556-.619 43.023 43.023 0 015.583-.265z"
          />
          <path
            id="Vector_364"
            fill="#FAFAFA"
            d="M185.402 172.632a42.546 42.546 0 01-5.379.741 41.778 41.778 0 01-5.406.387 42.077 42.077 0 015.372-.741c1.794-.25 3.602-.38 5.413-.387z"
          />
          <path
            id="Vector_365"
            fill="#FAFAFA"
            d="M187.034 175.862a7.335 7.335 0 01-1.612.265 61.61 61.61 0 01-3.916.313 65.99 65.99 0 01-3.924.081 6.977 6.977 0 01-1.632-.109 8.68 8.68 0 011.625-.142l3.917-.184 3.91-.211a7.483 7.483 0 011.632-.013z"
          />
          <path
            id="Vector_366"
            fill="#FFBE9D"
            d="M198.975 176.8l-3.686-.578-13.729.333s-.714-.204-.095-.598c.299-.191 5.351-1.442 5.351-1.442l-7.248.585a9.523 9.523 0 012.76-3.305c1.755-1.197 5.155-1.972 5.155-1.972l-2.197-1.666s7.174-.938 8.099-.122c.925.816 2.04 2.094 2.04 2.094l3.965-.041"
          />
          <path
            id="Vector_367"
            fill="#EB996E"
            d="M189.346 174.114c0 .102-2.169.17-4.821.428-2.652.259-4.801.585-4.815.483a5.215 5.215 0 011.36-.36c.864-.163 2.04-.347 3.4-.476 1.36-.129 2.55-.17 3.434-.17a5.408 5.408 0 011.442.095z"
          />
          <path
            id="Vector_368"
            fill="#EB996E"
            d="M188.924 172.326a25.39 25.39 0 01-4.12.761c-1.375.258-2.77.395-4.169.408a25.412 25.412 0 014.121-.775c1.376-.25 2.77-.382 4.168-.394z"
          />
        </g>
        <motion.g
          id="curly-woman"
          animate={{ rotate: [1.1, 0, 1.1] }}
          transition={{ duration: 2.4, repeat: Infinity }}
        >
          <path
            id="Vector_369"
            fill="#455A64"
            d="M121.74 134.45l-.618 2.033h9.839l-.523-1.381-8.698-.652z"
          />
          <path
            id="Vector_370"
            fill="#263238"
            d="M131.274 140.882l-.163-.129h-10.146l-.156.129h10.465z"
          />
          <path
            id="Vector_371"
            fill="#263238"
            d="M129.567 152.136l2.081-15.368h-11.22l2.088 15.368s4.277.619 7.051 0z"
          />
          <path
            id="Vector_372"
            fill="#455A64"
            d="M131.784 137h-11.485v-.177a.91.91 0 01.918-.918h9.649a.919.919 0 01.918.918V137z"
          />
          <path
            id="Vector_373"
            fill="#DADADA"
            d="M130.472 146.887h-8.861l-.802-6.005h10.465l-.802 6.005z"
          />
          <path
            id="Vector_374"
            fill="#263238"
            d="M127.282 144.84c.572-1.013.884-2.264-.265-2.992 0 0-1.088-.68-2.285.619-.931 1.02-1.36 2.217-.618 3.135.911 1.122 2.59.265 3.168-.762zm-1.788-.156a2.369 2.369 0 00-.959 1.128c-.109-.727.204-1.135 1.279-1.972a2.728 2.728 0 001.122-1.856c.571 1.38-.674 2.176-1.442 2.706v-.006z"
          />
          <path
            id="Vector_375"
            fill="#263238"
            d="M121.611 134.878l1.918.279v.394h-2.122l.204-.673z"
          />
          <path
            id="Vector_376"
            fill="#AA6550"
            d="M125.018 144.996s-16.47 16.558-17.932 17c0 0-4.998-9.737-5.44-10.091l-9.601 5.399 5.63 12.077a8.965 8.965 0 002.675 3.734 8.969 8.969 0 008.747 1.53 8.977 8.977 0 003.783-2.605l16.184-19.081 3.264-3.617a8.527 8.527 0 001.292-3.298 5.846 5.846 0 00-.136-2.204 2.224 2.224 0 00-.088-1.706 1.269 1.269 0 00-.884-.578c.714-1.768-1.224-2.646-2.197-1.184"
          />
          <path
            id="Vector_377"
            fill="#AA6550"
            d="M124.44 145.602a3.818 3.818 0 00-1.489-2.04 4.157 4.157 0 00-2.285-.898s.197-1.183 2.149-.925c1.951.259 2.482 1.523 4.896.925l.523 2.258-3.794.68z"
          />
          <path
            id="Vector_378"
            fill="#995037"
            d="M132.688 141.243c.089.041-.19.741-.836 1.36-.646.619-1.36.952-1.36.87 0-.081.53-.496 1.129-1.115.598-.619.979-1.163 1.067-1.115z"
          />
          <path
            id="Vector_379"
            fill="#995037"
            d="M133.443 143.793c.089 0-.061.537-.462 1.013-.401.476-.864.721-.918.639-.055-.081.279-.421.646-.863s.639-.837.734-.789z"
          />
          <path
            id="Vector_380"
            fill="#995037"
            d="M105.706 168.64a11.198 11.198 0 01.211-3.577 10.938 10.938 0 011.02-3.441c.095 0-.34 1.564-.68 3.496-.34 1.931-.442 3.522-.551 3.522z"
          />
          <path
            id="Vector_381"
            fill="#455A64"
            d="M46.498 135.612l-.618 2.027h9.832l-.516-1.381-8.698-.646z"
          />
          <path
            id="Vector_382"
            fill="#263238"
            d="M56.032 142.045l-.163-.136H45.723l-.163.136h10.472z"
          />
          <path
            id="Vector_383"
            fill="#263238"
            d="M54.318 153.292l2.122-15.361H45.186l2.088 15.361s4.27.626 7.044 0z"
          />
          <path
            id="Vector_384"
            fill="#455A64"
            d="M56.542 138.162H45.057v-.183a.916.916 0 01.918-.918h9.642a.924.924 0 01.925.918v.183z"
          />
          <path
            id="Vector_385"
            fill="#DADADA"
            d="M55.23 148.043h-8.868l-.802-5.998h10.472l-.802 5.998z"
          />
          <path
            id="Vector_386"
            fill="#263238"
            d="M52.034 146.01c.57-1.02.89-2.272-.259-2.999 0 0-1.088-.68-2.291.619-.925 1.026-1.36 2.216-.612 3.141.91 1.109 2.59.252 3.162-.761zm-1.789-.164c-.43.271-.764.667-.959 1.136-.102-.734.204-1.142 1.279-1.972a2.756 2.756 0 001.115-1.863c.585 1.38-.68 2.169-1.435 2.699z"
          />
          <path
            id="Vector_387"
            fill="#263238"
            d="M46.37 136.034l1.91.279v.394h-2.115l.204-.673z"
          />
          <path
            id="Vector_388"
            fill="#AA6550"
            d="M46.498 176.222s-1.951-2.305-1.448-18.754l-.116-8.84 7.664-2.312 5.297 29.804-11.397.102z"
          />
          <path
            id="Vector_389"
            fill="#AA6550"
            d="M52.36 146.88a5.055 5.055 0 011.673-2.312 4.162 4.162 0 012.285-.891s-.198-1.19-2.156-.925c-1.958.265-2.475 1.523-4.89.925l-2.794 4.991 5.882-1.788z"
          />
          <path
            id="Vector_390"
            fill="#AA6550"
            d="M49.783 144.078c-.116-.306-4.08-3.488-4.08-3.488s-2.04.082-1.279 1.938a1.67 1.67 0 00-1.035 1.793c.036.247.127.482.267.689 0 0-.898.646-.265 2.448a16.504 16.504 0 001.727 3.4l4.665-6.78z"
          />
          <path
            id="Vector_391"
            fill="#AA6550"
            d="M50.87 161.656l-4.46 10.656a6.045 6.045 0 003.91 7.582 6.04 6.04 0 007.575-3.774l3.577-10.54-10.601-3.924z"
          />
          <path
            id="Vector_392"
            fill="#995037"
            d="M46.811 171.442a5.665 5.665 0 01.36-1.476c.28-.898.68-2.122 1.238-3.448.558-1.326 1.088-2.502 1.51-3.338.194-.48.453-.93.768-1.34a7.278 7.278 0 01-.544 1.421c-.36.864-.87 2.04-1.407 3.366-.538 1.326-.993 2.53-1.36 3.4-.14.489-.33.963-.565 1.415z"
          />
          <path
            id="Vector_393"
            fill="#995037"
            d="M49.96 163.499c0 .109-.762.211-1.524.741-.761.531-1.156 1.197-1.244 1.15-.088-.048.184-.864 1.04-1.442a2.103 2.103 0 011.727-.449z"
          />
          <path
            id="Vector_394"
            fill="#263238"
            d="M68.89 106.57c.443 1.278-1.074 2.373-2.393 2.672-1.319.299-2.924.496-3.529 1.707-.524 1.054 0 2.387-.47 3.461-.904 2.04-4.576 1.618-5.296 3.733-.49 1.449.87 2.951.68 4.475-.143 1.312-1.36 2.21-2.4 2.998-1.041.789-2.17 1.898-2 3.21.19 1.51 2.04 2.557 1.87 4.08-.17 1.523-2.183 2.183-2.475 3.658-.34 1.748 1.85 2.877 2.55 4.516.544 1.251.21 2.862 1.115 3.876.904 1.013 2.618.836 3.978.564l13.6-2.883c-.918-2.156-.054-4.672 1.258-6.61 1.312-1.938 3.06-3.57 4.182-5.623 1.122-2.054 1.496-4.794 0-6.61"
          />
          <path
            id="Vector_395"
            fill="#263238"
            d="M92.827 117.456a15.064 15.064 0 00-.449-2.148 2.033 2.033 0 00-.544-.884c-.265-.259-.619-.096-.836.272a6.58 6.58 0 000 5.514c.217.368.57.531.836.279.25-.246.437-.549.544-.884.2-.705.35-1.423.449-2.149.053.195.069.398.047.599a6.01 6.01 0 01-.258 1.632 2.4 2.4 0 01-.592 1.027.768.768 0 01-.727.17 1.233 1.233 0 01-.571-.49 6.645 6.645 0 010-5.882c.133-.218.333-.388.57-.483a.737.737 0 01.728.17c.282.284.485.635.592 1.02.16.53.248 1.079.258 1.632a1.67 1.67 0 01-.047.605z"
          />
          <path
            id="Vector_396"
            fill="#263238"
            d="M70.618 129.989c-2.462-4.162-3.162-9.704-3.788-14.498-.292-2.257-.809-5.44.782-6.881 1.7-1.51 1.864-3.842 3.815-5.005a6.003 6.003 0 016.447.068"
          />
          <path
            id="Vector_397"
            fill="#AA6550"
            d="M77.445 144.724a6.664 6.664 0 007.194-6.378c.157-3.638.286-7.31.286-7.31s5.515-.476 5.923-5.916c.408-5.44.272-18.027.272-18.027a19.003 19.003 0 00-19.196 1.177l.285 19.182v10.316c-.007 3.427 1.782 6.684 5.236 6.956z"
          />
          <path
            id="Vector_398"
            fill="#263238"
            d="M85.435 118.422a2.78 2.78 0 00-3.72-.823 3.162 3.162 0 00-1.067 3.958 4.197 4.197 0 002.666 2.04 2.084 2.084 0 011.632 2.257c-.157 1.36-2.435 1.877-3.468 3.006-1.598 1.754-5.107 2.489-7.48 2.761-2.374.272-2.04.292-3.4-1.619a7.384 7.384 0 01-1.313-4.426c-.068-4.017-.138-8.036-.21-12.057-.087-1.448.01-2.901.285-4.325a5.822 5.822 0 012.292-3.57c1.326-.87 3.005-.938 4.522-1.421 1.89-.605 3.495-1.856 5.263-2.754 1.768-.897 3.937-1.428 5.705-.537 1.272.68 2.122 1.918 3.352 2.625 1.762 1.006 4.162.789 5.012 3.4a4.398 4.398 0 01-.04 2.774c-1.204 3.516-5.577 4.93-8.718 3.4-.062.599.523 1.931-.11 3.815-.203.598-.278 1.523-1.223 1.537"
          />
          <path
            id="Vector_399"
            fill="#995037"
            d="M84.45 121.822s0 .102-.076.231a.81.81 0 01-.605.306 1.5 1.5 0 01-1.407-1.509 1.572 1.572 0 01.4-1.156c.231-.245.53-.416.858-.49.557-.116.884.095.856.129-.027.034-.333 0-.782.136a1.36 1.36 0 00-.945 1.36 1.266 1.266 0 001.02 1.251c.456.028.646-.299.68-.258z"
          />
          <path
            id="Vector_400"
            fill="#263238"
            d="M92.84 117.191a19.853 19.853 0 01-3.672.782c-1.223.259-2.469.402-3.72.429a20.305 20.305 0 013.666-.782 20.04 20.04 0 013.726-.429z"
          />
          <path
            id="Vector_401"
            fill="#263238"
            d="M71.06 135.32a1.59 1.59 0 001.89.761 4.566 4.566 0 001.836-1.244 4.77 4.77 0 011.816-1.272c1.448-.442 3.23.531 4.454-.374 1.108-.816.966-2.679 2.04-3.522.578-.456 1.36-.51 1.979-.945 1.101-.816.925-2.639 0-3.652-.925-1.013-2.326-1.428-3.645-1.795l-11.716 4.991 1.346 7.052z"
          />
          <path
            id="Vector_402"
            fill="#455A64"
            d="M85 114.145l-.102.068c-.097.066-.2.125-.306.177a6.894 6.894 0 01-1.285.462c-.699.161-1.408.272-2.122.333a22.6 22.6 0 00-2.801.388 5.304 5.304 0 00-2.89 1.577c-.79.918-1.082 2.238-1.694 3.516a4.601 4.601 0 01-1.36 1.714 7.14 7.14 0 01-2.04 1.006c-1.434.483-3.019.768-4.216 1.788a2.965 2.965 0 00-1.08 1.911c-.065.758-.065 1.52 0 2.278a3.72 3.72 0 01-2.04 3.767c-1.225.721-2.51 1.109-3.537 1.762-.497.294-.925.69-1.258 1.162a3.03 3.03 0 00-.476 1.49c-.001.935.127 1.867.381 2.767.172.705.236 1.432.19 2.156a2.968 2.968 0 01-.387 1.305 1.35 1.35 0 01-.3.374c.34-.501.53-1.088.551-1.693a7.295 7.295 0 00-.251-2.094 10.344 10.344 0 01-.435-2.829 3.303 3.303 0 01.496-1.625 4.392 4.392 0 011.36-1.265c1.068-.68 2.353-1.081 3.522-1.788a3.866 3.866 0 001.456-1.408 3.903 3.903 0 00.38-2.074 14.055 14.055 0 010-2.332 3.403 3.403 0 011.204-2.136c1.299-1.094 2.938-1.36 4.338-1.849a6.651 6.651 0 001.945-.945 4.22 4.22 0 001.279-1.592c.598-1.217.904-2.57 1.76-3.556a5.565 5.565 0 013.06-1.632 22.15 22.15 0 012.836-.34c.71-.044 1.417-.135 2.115-.272.585-.14 1.156-.331 1.707-.571z"
          />
          <path
            id="Vector_403"
            fill="#455A64"
            d="M81.818 116.096c0 .034-.422 0-1.143.164a5.129 5.129 0 00-2.625 1.57 4.76 4.76 0 00-1.047 1.83 8.062 8.062 0 00-.21 2.448 6.388 6.388 0 01-.409 2.794c-.227.471-.58.868-1.02 1.15-.437.269-.891.51-1.36.72a3.435 3.435 0 00-1.162.83 2.04 2.04 0 00-.456 1.251c-.068.911.13 1.925-.313 2.795a4.651 4.651 0 01-1.822 1.809c-.68.408-1.36.68-1.925 1.047-.52.285-.981.666-1.36 1.122a3.11 3.11 0 00-.584 1.176 2.722 2.722 0 000 1.129.62.62 0 01-.116-.279 1.937 1.937 0 01-.04-.877c.089-.461.282-.896.564-1.271a4.747 4.747 0 011.36-1.211c.57-.367 1.237-.68 1.897-1.088a4.295 4.295 0 001.68-1.7c.387-.755.21-1.686.265-2.672a2.39 2.39 0 01.53-1.455 3.752 3.752 0 011.278-.918c.918-.449 1.843-.857 2.217-1.714a6.12 6.12 0 00.395-2.638 8.36 8.36 0 01.258-2.537c.23-.716.62-1.369 1.142-1.911a5.12 5.12 0 012.782-1.536c.287-.054.578-.077.87-.068.12-.011.24.003.354.04z"
          />
          <path
            id="Vector_404"
            fill="#DADADA"
            d="M63.077 184.334l3.794-11.022-2.638-13.376-2.04 5.902-12.777-4.678s8.262-16.51 8.67-17.381c3.78-7.929 13.838-7.929 13.838-7.929l12.804.334a13.99 13.99 0 0111.002 4.27c3.666 3.692 12.03 19.856 12.03 19.856l-13.478 4.196-2.666-4.985.776 10.479 2.652 16.021-31.967-1.687z"
          />
          <path
            id="Vector_405"
            fill="#13CB6E"
            d="M95.254 139.992c0 .095-7.779.176-17.367.176-9.588 0-17.367-.088-17.367-.176 0-.089 7.78-.184 17.374-.184 9.595 0 17.36.082 17.36.184z"
          />
          <path
            id="Vector_406"
            fill="#263238"
            d="M89.814 139.604c0 .041-.36.054-.938.299a4.328 4.328 0 00-1.952 1.741 5.808 5.808 0 00-.795 3.774 14.824 14.824 0 001.51 4.624 597.553 597.553 0 013.74 8.16l1.087 2.496c.15.299.269.614.354.938a5.174 5.174 0 01-.49-.87c-.312-.646-.72-1.469-1.203-2.448-1-2.04-2.346-4.944-3.842-8.16a14.666 14.666 0 01-1.517-4.76 6.02 6.02 0 01.925-3.965 4.257 4.257 0 012.129-1.734c.234-.091.483-.144.734-.156.204.047.258.047.258.061z"
          />
          <path
            id="Vector_407"
            fill="#263238"
            d="M67 173.917c-.108 0-1.645-6.923-3.4-15.497-1.754-8.575-3.182-15.559-3.08-15.579.102-.021 1.639 6.915 3.4 15.497s3.182 15.552 3.08 15.579z"
          />
          <path
            id="Vector_408"
            fill="#263238"
            d="M86.238 163.506a3.13 3.13 0 010 .925 8.766 8.766 0 01-.62 2.441 11.877 11.877 0 01-5.596 5.97 16.456 16.456 0 01-8.078 1.619 18.99 18.99 0 01-2.496-.252 3.896 3.896 0 01-.89-.211c0-.074 1.298.17 3.4.191a17.225 17.225 0 007.894-1.693 12.042 12.042 0 005.522-5.733c.425-1.044.715-2.139.864-3.257z"
          />
          <path
            id="Vector_409"
            fill="#13CB6E"
            d="M99.6 145.731c0 .102-9.52.183-21.237.183-11.716 0-21.243-.081-21.243-.183s9.52-.177 21.236-.177c11.717 0 21.244.082 21.244.177z"
          />
          <path
            id="Vector_410"
            fill="#13CB6E"
            d="M102.741 151.075c0 .102-10.764.177-24.031.177s-24.031-.075-24.031-.177 10.757-.176 24.031-.176 24.031.061 24.031.176z"
          />
          <path
            id="Vector_411"
            fill="#13CB6E"
            d="M104.856 155.978c0 .096-11.818.177-26.39.177-14.573 0-26.392-.081-26.392-.177 0-.095 11.819-.176 26.391-.176 14.573 0 26.391.074 26.391.176z"
          />
          <path
            id="Vector_412"
            fill="#13CB6E"
            d="M63.308 161.962a11.554 11.554 0 01-1.836-.503c-1.122-.333-2.672-.795-4.393-1.251a151.93 151.93 0 00-4.44-1.095 10.56 10.56 0 01-1.843-.476 9.86 9.86 0 011.89.231c1.156.204 2.72.537 4.482.993a48.46 48.46 0 014.379 1.36 8.854 8.854 0 011.761.741z"
          />
          <path
            id="Vector_413"
            fill="#13CB6E"
            d="M91.916 161.085a6.18 6.18 0 01-1.088.034h-2.972l-9.785-.102H65.314a5.908 5.908 0 01-1.088-.047c.36-.058.724-.09 1.088-.096.68-.034 1.714-.074 2.965-.108 2.509-.075 5.97-.13 9.792-.109 3.821.02 7.29.102 9.792.204 1.251.047 2.264.095 2.965.143.364-.002.728.025 1.088.081z"
          />
          <path
            id="Vector_414"
            fill="#13CB6E"
            d="M106.882 158.637h-.068l-.408.082-1.53.265a313.649 313.649 0 01-10.125 1.537c-.698.126-1.405.194-2.115.204.68-.208 1.376-.358 2.081-.449 1.285-.231 3.074-.53 5.053-.83 1.978-.299 3.767-.55 5.072-.68a30.68 30.68 0 011.544-.156c.143-.009.286-.009.428 0 .048 0 .075 0 .123.034.047.034-.048-.007-.055-.007z"
          />
          <path
            id="Vector_415"
            fill="#13CB6E"
            d="M85.632 165.832c0 .095-4.522.176-10.098.176-5.576 0-10.098-.081-10.098-.176 0-.096 4.522-.177 10.098-.177 5.576 0 10.098.075 10.098.177z"
          />
          <path
            id="Vector_416"
            fill="#13CB6E"
            d="M92.16 167.464c-1.14.174-2.295.233-3.447.176a17.217 17.217 0 01-3.448-.176 16.575 16.575 0 013.448-.177 16.575 16.575 0 013.447.177z"
          />
          <path
            id="Vector_417"
            fill="#13CB6E"
            d="M81.22 171.761c-.704.113-1.416.165-2.13.157a80.537 80.537 0 01-5.133 0c-2.006-.062-3.829-.15-5.134-.252a12.038 12.038 0 01-2.115-.258 13.04 13.04 0 012.128 0l5.128.149 5.127.089c.711-.02 1.423.018 2.128.115z"
          />
          <path
            id="Vector_418"
            fill="#13CB6E"
            d="M92.704 173.203a66.621 66.621 0 01-6.922.177c-2.309.061-4.62.002-6.922-.177a68.916 68.916 0 016.922-.177c2.309-.057 4.62.002 6.922.177z"
          />
          <path
            id="Vector_419"
            fill="#13CB6E"
            d="M93.745 178.221a6.3 6.3 0 01-1.115.048h-3.033c-2.564 0-6.12-.095-10.017-.218-3.896-.122-7.48-.279-10.01-.435-1.278-.088-2.311-.156-3.025-.224a8.258 8.258 0 01-1.109-.136c.371-.036.745-.036 1.116 0l3.032.108 10.01.334 10.01.319 3.032.123a6.33 6.33 0 011.109.081z"
          />
          <path
            id="Vector_420"
            fill="#13CB6E"
            d="M94.69 183.913a7.977 7.977 0 01-1.217 0l-3.298-.129a973.399 973.399 0 01-10.88-.551c-4.257-.238-8.085-.469-10.88-.68l-3.298-.238a9.838 9.838 0 01-1.21-.136 7.194 7.194 0 011.21 0l3.298.129c2.781.122 6.63.32 10.88.558 4.25.238 8.092.469 10.88.68l3.291.238c.411.007.82.05 1.224.129z"
          />
          <g id="Group_13" opacity="0.2">
            <path
              id="Vector_421"
              fill="#000"
              d="M67.946 173.06c2.4 1.795 5.943 1.197 8.908.68a12.92 12.92 0 007.752-4.202 5.184 5.184 0 001.441-3.666c-.115-1.36-1.305-2.645-2.645-2.482-1.53.184-2.271 1.904-3.012 3.264A11.892 11.892 0 0168 172.502s-.483.218-.054.558z"
              opacity="0.2"
            />
          </g>
          <g id="Group_14" opacity="0.2">
            <path
              id="Vector_422"
              fill="#000"
              d="M86.238 146.975c.748 6.045 1.727 11.002 2.985 16.952.619 2.931 1.36 5.991 3.4 8.201l-1.156-13.396-5.23-11.757z"
              opacity="0.2"
            />
          </g>
          <path
            id="Vector_423"
            fill="#263238"
            d="M69.088 134.361a4.342 4.342 0 00-2.739 4.682c.094.647.333 1.264.699 1.805.31.331.503.754.55 1.204-.054 1.074-1.788 1.102-2.338 2.04a1.674 1.674 0 00.462 1.952 14.69 14.69 0 001.795 1.196 2.197 2.197 0 011.102 1.748c.605 0 .965-.748.87-1.36a5.8 5.8 0 00-.748-1.652 1.744 1.744 0 01-.075-1.734c.402-.565 1.21-.585 1.857-.837a2.59 2.59 0 001.36-3.325c-.395-.877-1.299-1.761-.857-2.604.245-.476.816-.68 1.278-.918a3.335 3.335 0 001.44-4.265 3.332 3.332 0 00-1.004-1.284"
          />
          <path
            id="Vector_424"
            fill="#AA6550"
            d="M56.733 237.796l-3.795 70.285 11.587 2.801 8.69-71.604-16.483-1.482z"
          />
          <path
            id="Vector_425"
            fill="#13CB6E"
            d="M52.591 307.809l-1.972 8.683h13.335l.571-5.65-11.934-3.033z"
          />
          <path
            id="Vector_426"
            fill="#263238"
            d="M64.226 315.234c-.64.101-1.29.137-1.938.109-1.204 0-2.863 0-4.692-.088l-4.678-.197c-.65.008-1.299-.044-1.938-.157a9.59 9.59 0 011.944-.102c1.204 0 2.856 0 4.686.089l4.678.197c.65-.009 1.298.041 1.938.149z"
          />
          <path
            id="Vector_427"
            fill="#263238"
            d="M58.575 309.502a10.81 10.81 0 010 2.815c0 .94-.127 1.875-.374 2.781a10.602 10.602 0 010-2.808c.004-.942.13-1.879.374-2.788z"
          />
          <g id="Group_15" opacity="0.6">
            <path
              id="Vector_428"
              fill="#fff"
              d="M50.62 316.492h13.334l.13-1.258-13.227-.333-.238 1.442"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_429"
            fill="#AA6550"
            d="M98.668 291.645l2.815 21.196 10.255-.714-2.156-19.435-10.914-1.047z"
          />
          <path
            id="Vector_430"
            fill="#13CB6E"
            d="M111.846 309.828s11.56 3.897 11.717 5.998l-22.039.639-.197-5.76.809.164a16.4 16.4 0 009.71-1.041z"
          />
          <g id="Group_16" opacity="0.6">
            <path
              id="Vector_431"
              fill="#fff"
              d="M103.904 311.712a.895.895 0 00-.592 1.04.862.862 0 001.027.599.949.949 0 00.525-.435.948.948 0 00.101-.674.906.906 0 00-1.143-.503"
              opacity="0.6"
            />
          </g>
          <g id="Group_17" opacity="0.6">
            <path
              id="Vector_432"
              fill="#fff"
              d="M101.524 316.465v-1.788l21.128.054s.992.401.904 1.095l-22.032.639z"
              opacity="0.6"
            />
          </g>
          <path
            id="Vector_433"
            fill="#263238"
            d="M112.2 309.706c0 .109-.537.17-1.061.537-.523.367-.782.816-.884.782-.102-.034.041-.68.68-1.095.639-.414 1.265-.326 1.265-.224z"
          />
          <path
            id="Vector_434"
            fill="#263238"
            d="M114.539 310.597c0 .102-.435.299-.789.761-.353.463-.442.952-.55.952-.109 0-.191-.612.258-1.176.449-.565 1.074-.639 1.081-.537z"
          />
          <path
            id="Vector_435"
            fill="#263238"
            d="M115.933 313.323c-.102 0-.265-.509 0-1.108.265-.598.755-.843.803-.755.047.089-.252.415-.456.905-.204.489-.245.945-.347.958z"
          />
          <path
            id="Vector_436"
            fill="#263238"
            d="M111.955 309.652a2.12 2.12 0 01-1.101 0 5.04 5.04 0 01-1.809-.64 1.255 1.255 0 01-.34-.251.475.475 0 01-.068-.571.591.591 0 01.462-.259c.142-.006.285.01.422.048.233.047.461.117.68.211a3.99 3.99 0 011.04.639c.524.462.714.857.68.877-.034.02-.306-.292-.836-.68a4.185 4.185 0 00-1.639-.7c-.238-.055-.435-.055-.49.054-.054.109 0 .054 0 .122.077.071.162.132.252.184a6.188 6.188 0 001.673.694c.364.062.724.153 1.074.272z"
          />
          <path
            id="Vector_437"
            fill="#263238"
            d="M111.819 309.808a1.787 1.787 0 01-.319-1.054 3.569 3.569 0 01.115-1.217c.066-.234.153-.462.259-.68a.897.897 0 01.68-.578.511.511 0 01.469.292c.059.127.101.263.122.401.041.246.041.496 0 .742a2.485 2.485 0 01-.435 1.156c-.415.584-.857.727-.87.68.234-.261.45-.539.646-.83.181-.322.29-.679.319-1.047a2.205 2.205 0 00-.041-.633c-.04-.238-.136-.421-.217-.394a.846.846 0 00-.381.374 3.902 3.902 0 00-.435 1.714c.02.666.149 1.06.088 1.074z"
          />
          <path
            id="Vector_438"
            fill="#13CB6E"
            d="M63.077 184.334s-8.16 13.6-8.16 21.72l-2.04 99.014 14.062 2.04 9.901-96.736s22.508 98.008 23.27 98.008c.761 0 12.457-2.094 12.457-2.094l-17.734-121.51-31.756-.442z"
          />
          <path
            id="Vector_439"
            fill="#263238"
            d="M85.775 212.554c0 .109-4.311-.85-9.567-2.128-5.257-1.278-9.52-2.414-9.473-2.516.048-.102 4.311.85 9.568 2.135 5.256 1.285 9.5 2.407 9.472 2.509z"
          />
          <path
            id="Vector_440"
            fill="#263238"
            d="M68.3 208.712a1.71 1.71 0 010 .252l-.11.727c-.102.68-.251 1.598-.442 2.788-.394 2.428-.945 5.93-1.598 10.275a742.969 742.969 0 00-4.31 34c-1.374 13.308-2.224 25.446-2.68 34.191a860.34 860.34 0 00-.51 10.383l-.136 2.822-.034.735c.007.083.007.168 0 .251a1.503 1.503 0 010-.251v-.735c0-.68 0-1.618.061-2.828.061-2.455.197-6.005.401-10.391a640.183 640.183 0 012.578-34.204c1.366-13.328 3.032-25.344 4.406-34 .68-4.338 1.278-7.84 1.714-10.254.217-1.19.387-2.122.51-2.782.06-.299.108-.544.142-.72a1.227 1.227 0 01.007-.259z"
          />
          <path
            id="Vector_441"
            fill="#263238"
            d="M88.645 210.351c.095 0 4.012 21.713 8.751 48.525 4.74 26.812 8.507 48.579 8.412 48.6-.095.02-4.019-21.713-8.758-48.539-4.74-26.826-8.507-48.565-8.405-48.586z"
          />
        </motion.g>
      </g>
    </svg>
  );
}
