import React from 'react';
import { motion } from 'framer-motion';

export default function CodeTyping() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="450"
      height="300"
      fill="none"
      viewBox="0 0 450 300"
      className="w-full"
    >
      <g id="about-us">
        <g id="background">
          <path
            id="Vector"
            fill="#EBEBEB"
            d="M105.84 47.754H14.46v61.182h91.38V47.754z"
          />
          <path
            id="Vector_2"
            fill="#B0B9CB"
            d="M105.834 108.942v-1.116-3.228c0-2.832 0-6.984-.03-12.306 0-10.626-.042-25.908-.072-44.538l.132.132-91.368.036.162-.168v61.2l-.15-.15 65.832.072 18.804.042H105.882 99.21l-18.774.036-65.928.078h-.15v-.15V47.52h.174l91.368.036h.132v.126c-.03 18.666-.054 33.972-.066 44.622 0 5.304-.03 9.45-.036 12.276v3.204c0 .732-.096 1.158-.096 1.158z"
          />
          <path
            id="Vector_3"
            fill="#B0B9CB"
            d="M14.46 53.214c0-.09 20.436-.162 45.648-.162 25.212 0 45.654.072 45.654.162 0 .09-20.436.168-45.654.168-25.218 0-45.648-.072-45.648-.168z"
          />
          <g id="Group" opacity="0.3">
            <path
              id="Vector_4"
              fill="#B0B9CB"
              d="M18.06 50.46a.732.732 0 11-1.463 0 .732.732 0 011.463 0z"
              opacity="0.3"
            />
          </g>
          <g id="Group_2" opacity="0.3">
            <path
              id="Vector_5"
              fill="#B0B9CB"
              d="M20.574 50.46a.732.732 0 11-1.463 0 .732.732 0 011.463 0z"
              opacity="0.3"
            />
          </g>
          <g id="Group_3" opacity="0.3">
            <path
              id="Vector_6"
              fill="#B0B9CB"
              d="M23.046 50.46a.732.732 0 11-1.463 0 .732.732 0 011.463 0z"
              opacity="0.3"
            />
          </g>
          <path
            id="Vector_7"
            fill="#B0B9CB"
            d="M46.686 59.598H22.314v24.378h24.372V59.598z"
          />
          <path
            id="Vector_8"
            fill="#B0B9CB"
            d="M35.292 92.19H23.838v11.454h11.454V92.19z"
          />
          <path
            id="Vector_9"
            fill="#B0B9CB"
            d="M56.064 92.19H44.61v11.454h11.454V92.19z"
          />
          <path
            id="Vector_10"
            fill="#B0B9CB"
            d="M76.842 92.19H65.388v11.454h11.454V92.19z"
          />
          <path
            id="Vector_11"
            fill="#B0B9CB"
            d="M97.614 92.19H86.16v11.454h11.454V92.19z"
          />
          <path
            id="Vector_12"
            fill="#EBEBEB"
            d="M198.036 18.024h-78.714v31.974h78.714V18.024z"
          />
          <path
            id="Vector_13"
            fill="#B0B9CB"
            d="M198.036 49.998v-.6-1.662-6.36c0-5.52-.03-13.5-.048-23.4l.09.09-78.714.036.12-.126V49.95l-.114-.12 56.436.054 16.368.036h4.386-4.356l-16.344.03-56.514.06h-.12v-.12-32.1h.126l78.714.042h.084V18c0 9.906-.036 17.904-.048 23.436v7.986c0 .378-.066.576-.066.576z"
          />
          <path
            id="Vector_14"
            fill="#B0B9CB"
            d="M119.4 22.338c0-.072 17.592-.126 39.288-.126 21.696 0 39.3.054 39.3.126 0 .072-17.592.126-39.3.126s-39.288-.06-39.288-.126z"
          />
          <g id="Group_4" opacity="0.3">
            <path
              id="Vector_15"
              fill="#03B059"
              d="M122.694 20.4a.572.572 0 01-.671.56.573.573 0 01-.434-.758.572.572 0 01.505-.372.6.6 0 01.6.57z"
              opacity="0.3"
            />
          </g>
          <g id="Group_5" opacity="0.3">
            <path
              id="Vector_16"
              fill="#03B059"
              d="M124.65 20.4a.567.567 0 01-.681.56.57.57 0 01.111-1.13.599.599 0 01.57.57z"
              opacity="0.3"
            />
          </g>
          <g id="Group_6" opacity="0.3">
            <path
              id="Vector_17"
              fill="#03B059"
              d="M126.012 20.976a.57.57 0 100-1.14.57.57 0 000 1.14z"
              opacity="0.3"
            />
          </g>
          <path
            id="Vector_18"
            fill="#B0B9CB"
            d="M145.026 27.534h-19.014v19.014h19.014V27.534z"
          />
          <path
            id="Vector_19"
            fill="#B0B9CB"
            d="M192.6 32.628c0 .072-9.786.126-21.852.126s-21.852-.054-21.852-.126c0-.072 9.786-.126 21.852-.126s21.852.06 21.852.126z"
          />
          <path
            id="Vector_20"
            fill="#B0B9CB"
            d="M192.6 36.504c0 .066-9.786.12-21.852.12s-21.852-.054-21.852-.12 9.786-.126 21.852-.126 21.852.054 21.852.126z"
          />
          <path
            id="Vector_21"
            fill="#B0B9CB"
            d="M177 40.374a.965.965 0 01-.288 0h-.816l-3 .042-9.942.036-9.954.012-3-.042h-.822a1.007 1.007 0 01-.288 0c.096-.01.192-.01.288 0H150l3-.036 9.948-.042 9.942.042 3 .036h.816c.096-.027.195-.043.294-.048z"
          />
          <path
            id="Vector_22"
            fill="#B0B9CB"
            d="M410.4 60.696c.127-.187.276-.358.444-.51.354-.348.786-.786 1.296-1.296a263.04 263.04 0 014.344-4.2l.042-.036v.216a301.052 301.052 0 01-4.374-4.122l-1.302-1.272a3.01 3.01 0 01-.45-.498c.192.114.369.251.528.408l1.368 1.2a429.22 429.22 0 014.434 4.056l.12.114-.12.108-.036.036a312.985 312.985 0 01-4.41 4.14l-1.356 1.236a3.633 3.633 0 01-.528.42z"
          />
          <path
            id="Vector_23"
            fill="#B0B9CB"
            d="M397.122 48.972c-.13.183-.279.352-.444.504l-1.278 1.302a324.007 324.007 0 01-4.344 4.2l-.042.036v-.222c1.722 1.602 3.27 3.06 4.374 4.128l1.302 1.272c.17.147.321.314.45.498a3.003 3.003 0 01-.528-.414l-1.368-1.2a377.712 377.712 0 01-4.434-4.062l-.12-.108.12-.108.036-.042c1.71-1.626 3.27-3.09 4.41-4.14l1.344-1.224c.157-.16.332-.301.522-.42z"
          />
          <path
            id="Vector_24"
            fill="#B0B9CB"
            d="M400.536 63.456c-.078-.03 1.314-3.948 3.114-8.754 1.8-4.806 3.318-8.682 3.396-8.652.078.03-1.32 3.948-3.114 8.754-1.794 4.806-3.318 8.682-3.396 8.652z"
          />
          <path
            id="Vector_25"
            fill="#B0B9CB"
            d="M425.514 71.844v-.654-1.884-7.146c0-6.15-.036-14.94-.066-25.536l.126.126h-43.2l.15-.15v35.22l-.132-.132 31.2.066 8.844.036H425.604h-3.12l-8.82.036-31.302.066h-.132v-.138-35.37h43.482v.216c0 10.626-.048 19.446-.066 25.614v8.982c0 .432-.132.648-.132.648z"
          />
          <path
            id="Vector_26"
            fill="#B0B9CB"
            d="M425.856 41.85c0 .084-9.762.15-21.798.15-12.036 0-21.792-.066-21.792-.15 0-.084 9.756-.15 21.792-.15 12.036 0 21.798.066 21.798.15z"
          />
          <path
            id="Vector_27"
            fill="#B0B9CB"
            d="M385.8 39.276a.6.6 0 11-1.199 0 .6.6 0 011.199 0z"
          />
          <path
            id="Vector_28"
            fill="#B0B9CB"
            d="M387.936 39.276a.6.6 0 11-1.199 0 .6.6 0 011.199 0z"
          />
          <path
            id="Vector_29"
            fill="#B0B9CB"
            d="M390.054 39.276a.6.6 0 11-1.199 0 .6.6 0 011.199 0z"
          />
          <path
            id="Vector_30"
            fill="#03B059"
            d="M415.554 226.74c2.496-1.29 5.442-1.458 8.214-.996 1.488.246 3.042.69 4.056 1.8 1.014 1.11 1.2 3.084.042 4.05a3.929 3.929 0 01-2.556.648c-2.172 0-4.584-.408-6.318.9-.99.744-1.644 1.98-2.832 2.346a2.798 2.798 0 01-3.078-1.368 4.802 4.802 0 01-.348-3.528 5.398 5.398 0 012.82-3.852z"
          />
          <path
            id="Vector_31"
            fill="#03B059"
            d="M409.83 215.694c1.53 2.178 4.5 1.458 5.874.126 1.374-1.332 1.956-3.264 2.49-5.1 1.2-4.2 2.4-8.616 1.53-12.9a7.2 7.2 0 00-1.65-3.51 3.737 3.737 0 00-3.546-1.2c-1.5.396-2.4 1.86-3.102 3.252a30.768 30.768 0 00-3.036 13.2c0 2.124.24 4.35 1.44 6.108"
          />
          <path
            id="Vector_32"
            fill="#03B059"
            d="M405.636 218.82c1.656-1.968 1.974-4.836 1.236-7.302a14.07 14.07 0 00-4.326-6.276 23.33 23.33 0 00-7.746-4.572 8.316 8.316 0 00-3.486-.6 3.679 3.679 0 00-2.928 1.8c-.81 1.554 0 3.444.816 4.98a59.037 59.037 0 005.088 7.752c1.524 1.962 3.27 3.906 5.598 4.8 2.328.894 4.74.786 5.97-.828"
          />
          <path
            id="Vector_33"
            fill="#B0B9CB"
            d="M409.728 248.604c.08-.327.126-.66.138-.996.072-.72.156-1.626.264-2.706.108-1.08.294-2.49.6-3.966a30.92 30.92 0 011.446-4.686 17.284 17.284 0 012.4-4.236 9 9 0 013-2.514 8.495 8.495 0 012.55-.792c.332-.012.662-.061.984-.144a5.083 5.083 0 00-1.002 0 7.666 7.666 0 00-2.658.69 8.933 8.933 0 00-3.186 2.532 16.925 16.925 0 00-2.484 4.326 28.815 28.815 0 00-1.428 4.758 33.575 33.575 0 00-.528 4.02 60.6 60.6 0 00-.138 2.724c-.022.33-.008.662.042.99z"
          />
          <path
            id="Vector_34"
            fill="#B0B9CB"
            d="M409.914 246.522a2.099 2.099 0 000-.462v-1.314c0-1.146 0-2.796.06-4.836.096-4.08.45-8.472 1.128-14.658.642-5.82 1.506-12.6 2.4-17.004.192-1.002.39-1.902.6-2.688.21-.786.324-1.476.468-2.028.144-.552.228-.942.312-1.278.044-.148.07-.301.078-.456-.066.139-.12.284-.162.432-.096.328-.222.748-.378 1.26-.168.552-.342 1.2-.54 2.016-.198.816-.426 1.686-.642 2.682a149.343 149.343 0 00-2.538 15.774c-.678 6.204-.984 11.856-1.014 15.942 0 2.046 0 3.702.072 4.842.036.534.06.972.084 1.314.008.156.032.311.072.462z"
          />
          <path
            id="Vector_35"
            fill="#B0B9CB"
            d="M409.944 235.626a10.5 10.5 0 000-1.374 37.98 37.98 0 00-.42-3.72 49.56 49.56 0 00-1.2-5.4 44.483 44.483 0 00-2.268-6.42 34.325 34.325 0 00-6.66-10.374 16.548 16.548 0 00-1.566-1.452c-.24-.204-.456-.408-.672-.57l-.6-.42a8.595 8.595 0 00-1.158-.738 41.248 41.248 0 013.792 3.384 35.938 35.938 0 016.492 10.332 50.51 50.51 0 012.286 6.36c.501 1.76.911 3.544 1.23 5.346.288 1.548.45 2.808.552 3.684.025.459.09.914.192 1.362z"
          />
          <path
            id="Vector_36"
            fill="#B0B9CB"
            d="M399.414 254.844l1.092 14.952h19.212l1.092-14.952h-21.396z"
          />
          <path
            id="Vector_37"
            fill="#B0B9CB"
            d="M423.096 248.946h-25.542v5.892h25.542v-5.892z"
          />
          <path
            id="Vector_38"
            fill="#03B059"
            d="M397.554 269.226v1.632h25.122v-1.632h-25.122z"
          />
          <path
            id="Vector_39"
            fill="#B0B9CB"
            d="M399.93 273.738l-1.176-2.88h23.01l-1.374 2.88h-20.46z"
          />
        </g>
        <g id="laptop">
          <path
            id="Vector_40"
            fill="#455A64"
            d="M65.484 62.454H372a4.047 4.047 0 014.044 4.044V262.2a8.742 8.742 0 01-8.742 8.742H69.6a8.744 8.744 0 01-8.766-8.742V67.11a4.65 4.65 0 014.65-4.656z"
          />
          <path
            id="Vector_41"
            fill="#455A64"
            d="M47.652 255.498h341.526V265.5a8.232 8.232 0 01-8.232 8.232H55.884a8.231 8.231 0 01-8.232-8.232v-10.002z"
          />
          <path
            id="Vector_42"
            fill="#B0B9CB"
            d="M366.714 70.74H69.294v176.04h297.42V70.74z"
          />
          <path
            id="Vector_43"
            fill="#263238"
            d="M187.374 255.498l4.236 6.738h59.634l3.936-6.738h-67.806z"
          />
          <path
            id="Vector_44"
            fill="#263238"
            d="M358.608 80.94H78.12v165.84h280.488V80.94z"
          />
          <path
            id="Vector_45"
            fill="#B0B9CB"
            d="M99.642 241.908c-.144 0-.264-35.316-.264-78.876s.12-78.894.264-78.894c.144 0 .264 35.316.264 78.894s-.12 78.876-.264 78.876z"
          />
          <path
            id="Vector_46"
            fill="#B0B9CB"
            d="M93.984 98.442c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_47"
            fill="#B0B9CB"
            d="M94.332 89.49c0 .144-2.04.264-4.554.264-2.514 0-4.554-.12-4.554-.264 0-.144 2.04-.264 4.554-.264 2.514 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_48"
            fill="#B0B9CB"
            d="M93.984 108.09c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_49"
            fill="#B0B9CB"
            d="M93.984 117.738c0 .15-2.04.264-4.554.264-2.514 0-4.56-.114-4.56-.264 0-.15 2.04-.258 4.56-.258 2.52 0 4.554.12 4.554.258z"
          />
          <path
            id="Vector_50"
            fill="#B0B9CB"
            d="M93.984 127.392c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.114 4.554.264z"
          />
          <path
            id="Vector_51"
            fill="#B0B9CB"
            d="M93.984 137.04c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_52"
            fill="#B0B9CB"
            d="M93.984 146.688c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_53"
            fill="#B0B9CB"
            d="M93.984 156.336c0 .15-2.04.264-4.554.264-2.514 0-4.56-.114-4.56-.264 0-.15 2.04-.258 4.56-.258 2.52 0 4.554.114 4.554.258z"
          />
          <path
            id="Vector_54"
            fill="#B0B9CB"
            d="M93.984 165.99c0 .144-2.04.258-4.554.258-2.514 0-4.56-.114-4.56-.258 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.114 4.554.264z"
          />
          <path
            id="Vector_55"
            fill="#B0B9CB"
            d="M93.984 175.638c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_56"
            fill="#B0B9CB"
            d="M93.984 185.286c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_57"
            fill="#B0B9CB"
            d="M93.984 194.934c0 .15-2.04.264-4.554.264-2.514 0-4.56-.114-4.56-.264 0-.15 2.04-.258 4.56-.258 2.52 0 4.554.114 4.554.258z"
          />
          <path
            id="Vector_58"
            fill="#B0B9CB"
            d="M93.984 204.6c0 .144-2.04.258-4.554.258-2.514 0-4.56-.114-4.56-.258 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.102 4.554.264z"
          />
          <path
            id="Vector_59"
            fill="#B0B9CB"
            d="M93.984 214.236c0 .144-2.04.264-4.554.264-2.514 0-4.56-.12-4.56-.264 0-.144 2.04-.264 4.56-.264 2.52 0 4.554.12 4.554.264z"
          />
          <path
            id="Vector_60"
            fill="#B0B9CB"
            d="M93.984 233.532c0 .15-2.04.264-4.554.264-2.514 0-4.56-.114-4.56-.264 0-.15 2.04-.264 4.56-.264 2.52 0 4.554.132 4.554.264z"
          />
          <path
            id="Vector_61"
            fill="#13CB6E"
            d="M130.506 97.872c0 .144-5.292.264-11.82.264-6.528 0-11.826-.12-11.826-.264 0-.144 5.292-.264 11.826-.264 6.534 0 11.82.12 11.82.264z"
          />
          <path
            id="Vector_62"
            fill="#13CB6E"
            d="M163.44 117.738c0 .15-9.906.264-22.122.264s-22.122-.114-22.122-.264c0-.15 9.9-.258 22.122-.258s22.122.12 22.122.258z"
          />
          <path
            id="Vector_63"
            fill="#B0B9CB"
            d="M214.608 117.738c0 .15-9.906.264-22.122.264s-22.128-.114-22.128-.264c0-.15 9.906-.258 22.128-.258s22.122.12 22.122.258z"
          />
          <path
            id="Vector_64"
            fill="#B0B9CB"
            d="M195.054 127.392c0 .144-14.598.264-32.604.264s-32.604-.12-32.604-.264c0-.144 14.592-.264 32.604-.264s32.604.114 32.604.264z"
          />
          <path
            id="Vector_65"
            fill="#B0B9CB"
            d="M195.054 137.04c0 .144-14.598.264-32.604.264s-32.604-.12-32.604-.264c0-.144 14.592-.264 32.604-.264s32.604.12 32.604.264z"
          />
          <path
            id="Vector_66"
            fill="#B0B9CB"
            d="M239.94 165.99c0 .144-14.604.258-32.604.258s-32.61-.114-32.61-.258c0-.144 14.598-.264 32.61-.264s32.604.114 32.604.264z"
          />
          <path
            id="Vector_67"
            fill="#B0B9CB"
            d="M220.566 146.688c0 .144-14.598.264-32.598.264s-32.61-.12-32.61-.264c0-.144 14.598-.264 32.61-.264s32.598.12 32.598.264z"
          />
          <path
            id="Vector_68"
            fill="#FAFAFA"
            d="M317.586 165.99c0 .144-14.598.258-32.604.258s-32.604-.114-32.604-.258c0-.144 14.592-.264 32.604-.264s32.604.114 32.604.264z"
          />
          <path
            id="Vector_69"
            fill="#455A64"
            d="M308.592 137.04c0 .144-22.668.264-50.628.264s-50.628-.12-50.628-.264c0-.144 22.662-.264 50.628-.264s50.628.12 50.628.264z"
          />
          <path
            id="Vector_70"
            fill="#FAFAFA"
            d="M130.506 223.884c0 .144-5.292.264-11.82.264-6.528 0-11.826-.12-11.826-.264 0-.144 5.292-.264 11.826-.264 6.534 0 11.82.12 11.82.264z"
          />
          <path
            id="Vector_71"
            fill="#13CB6E"
            d="M331.758 146.688c0 .144-5.292.264-11.82.264-6.528 0-11.826-.12-11.826-.264 0-.144 5.292-.264 11.826-.264 6.534 0 11.82.12 11.82.264z"
          />
          <path
            id="Vector_72"
            fill="#13CB6E"
            d="M163.44 185.232c0 .144-9.906.264-22.122.264s-22.122-.12-22.122-.264c0-.144 9.9-.264 22.122-.264s22.122.114 22.122.264z"
          />
          <path
            id="Vector_73"
            fill="#B0B9CB"
            d="M195.054 194.88c0 .144-14.598.264-32.604.264s-32.604-.12-32.604-.264c0-.144 14.592-.264 32.604-.264s32.604.12 32.604.264z"
          />
          <path
            id="Vector_74"
            fill="#B0B9CB"
            d="M195.054 204.528c0 .144-14.598.264-32.604.264s-32.604-.12-32.604-.264c0-.144 14.592-.264 32.604-.264s32.604.12 32.604.264z"
          />
          <path
            id="Vector_75"
            fill="#B0B9CB"
            d="M220.566 214.2c0 .15-14.598.264-32.598.264s-32.61-.114-32.61-.264c0-.15 14.598-.258 32.61-.258s32.598.09 32.598.258z"
          />
          <path
            id="Vector_76"
            fill="#B0B9CB"
            d="M300.252 214.2c0 .15-14.598.264-32.598.264s-32.61-.114-32.61-.264c0-.15 14.598-.258 32.61-.258s32.598.09 32.598.258z"
          />
          <path
            id="Vector_77"
            fill="#455A64"
            d="M308.592 204.528c0 .144-22.668.264-50.628.264s-50.628-.12-50.628-.264c0-.144 22.662-.264 50.628-.264s50.628.12 50.628.264z"
          />
          <path
            id="Vector_78"
            fill="#263238"
            d="M338.064 261.6c0 .084-60.384.156-134.856.156S68.34 261.672 68.34 261.6s60.372-.156 134.868-.156 134.856.06 134.856.156z"
          />
        </g>
        <g id="plant-left">
          <path
            id="Vector_79"
            fill="#13CB6E"
            d="M58.242 208.698a34.237 34.237 0 00-32.67-8.97 59.489 59.489 0 0033.06 10.326"
          />
          <path
            id="Vector_80"
            fill="#13CB6E"
            d="M47.16 169.458c1.362-9.63-2.4-20.46-9.6-27.456-.234 9.54 3.792 20.274 9.6 27.456z"
          />
          <path
            id="Vector_81"
            fill="#13CB6E"
            d="M52.926 187.644a51.38 51.38 0 00-23.61-22.566 32.445 32.445 0 0023.7 22.668"
          />
          <path
            id="Vector_82"
            fill="#13CB6E"
            d="M54.12 191.148c-5.814.168-11.7-.036-17.322-1.524s-10.998-4.35-14.754-8.802a57.868 57.868 0 0131.986 9.762"
          />
          <path
            id="Vector_83"
            fill="#13CB6E"
            d="M51.168 179.592c2.952-11.064 7.8-18.714 16.518-24.912a22.997 22.997 0 01-16.518 24.912z"
          />
          <path
            id="Vector_84"
            fill="#13CB6E"
            d="M73.116 198.672a16.37 16.37 0 00-9.948-15.168c.486 5.712 4.974 12.318 9.948 15.168z"
          />
          <path
            id="Vector_85"
            fill="#13CB6E"
            d="M75.894 189.342a24.408 24.408 0 0115.984-20.814c-2.01 9.246-7.35 16.944-15.984 20.814z"
          />
          <path
            id="Vector_86"
            fill="#13CB6E"
            d="M71.706 205.896c2.052-2.934 5.4-4.758 8.862-5.664 3.462-.906 7.08-.99 10.656-1.062A24.921 24.921 0 0172 207.186"
          />
          <path
            id="Vector_87"
            fill="#13CB6E"
            d="M67.554 229.47a27.3 27.3 0 0133.336 15.798C88.8 243 76.83 237.6 67.554 229.47z"
          />
          <path
            id="Vector_88"
            fill="#13CB6E"
            d="M46.404 217.974A22.373 22.373 0 0134.05 236.13a1.412 1.412 0 01-1.092.18c-.546-.222-.6-.966-.528-1.554.876-7.614 6.522-15 13.974-16.8"
          />
          <path
            id="Vector_89"
            fill="#13CB6E"
            d="M41.274 217.2a18.354 18.354 0 01-26.802.138c8.52-.93 18.348-1.554 26.802-.138z"
          />
          <path
            id="Vector_90"
            fill="#263238"
            d="M39.996 149.094s0 .09.078.252l.264.72c.24.648.6 1.572 1.026 2.76.426 1.188.99 2.664 1.602 4.374l.984 2.736 1.05 3.078c1.512 4.314 3.186 9.492 4.998 15.258 1.812 5.766 3.642 12.15 5.478 18.882 1.836 6.732 3.414 13.2 4.8 19.068a315.265 315.265 0 013.294 15.714c.212 1.124.412 2.192.6 3.204s.35 1.966.486 2.862c.306 1.8.57 3.33.75 4.596.18 1.266.342 2.226.444 2.91.056.316.098.568.126.756.011.087.03.174.054.258a1.455 1.455 0 000-.264c0-.186-.054-.438-.096-.756-.09-.684-.216-1.668-.384-2.922a163.463 163.463 0 00-.696-4.608c-.144-.894-.3-1.854-.462-2.868-.162-1.014-.378-2.088-.6-3.21-.798-4.506-1.914-9.834-3.228-15.738-1.314-5.904-2.964-12.348-4.764-19.092-1.8-6.744-3.708-13.104-5.502-18.864a398.174 398.174 0 00-5.064-15.24c-.4-1.08-.762-2.106-1.086-3.078l-1.014-2.73c-.6-1.704-1.2-3.168-1.65-4.356-.45-1.188-.834-2.094-1.086-2.736a23.59 23.59 0 01-.288-.708 1.798 1.798 0 00-.114-.258z"
          />
          <path
            id="Vector_91"
            fill="#263238"
            d="M86.796 173.466s-.054.042-.15.138l-.408.426c-.354.378-.87.948-1.512 1.698a59.13 59.13 0 00-5.016 6.672 44.904 44.904 0 00-5.244 11.226c-1.41 4.464-2.28 9.54-3.15 14.85-1.728 10.62-3.858 20.136-5.4 27-.774 3.426-1.404 6.204-1.848 8.148-.21.942-.372 1.686-.486 2.214-.054.24-.09.432-.12.6a.632.632 0 000 .198s0-.066.06-.192.09-.336.15-.57l.552-2.202c.462-1.944 1.128-4.71 1.944-8.13 1.596-6.87 3.762-16.38 5.496-27 .87-5.31 1.728-10.374 3.108-14.82a45.41 45.41 0 015.154-11.184 65.402 65.402 0 014.92-6.732c.6-.756 1.128-1.338 1.464-1.728l.384-.45.102-.162z"
          />
          <path
            id="Vector_92"
            fill="#263238"
            d="M38.73 216.84c.099.033.202.051.306.054l.882.102.636.06.756.114c.54.078 1.158.162 1.8.306l1.062.216 1.134.282c.786.18 1.602.468 2.472.732a36.752 36.752 0 015.4 2.4 30.772 30.772 0 014.836 3.36 23.11 23.11 0 011.854 1.8c.522.535 1.013 1.1 1.47 1.692.388.481.75.981 1.086 1.5.275.382.524.783.744 1.2l.45.762c.108.174.168.264.18.258a.938.938 0 00-.12-.282l-.402-.798a9.277 9.277 0 00-.702-1.2 15.915 15.915 0 00-1.056-1.536 17.898 17.898 0 00-1.452-1.74 24.474 24.474 0 00-1.854-1.8 29.635 29.635 0 00-4.86-3.522 34.756 34.756 0 00-5.46-2.4c-.876-.252-1.698-.534-2.496-.702l-1.146-.27-1.05-.174c-.678-.138-1.302-.204-1.848-.264l-.756-.09-.642-.03-.888-.036a1.113 1.113 0 00-.336.006z"
          />
          <path
            id="Vector_93"
            fill="#13CB6E"
            d="M78.198 244.146v7.782h-1.95l-6.918 21.84H53.988l-6.702-21.624-2.598-.216v-7.782h33.51z"
          />
          <g id="Group_7" opacity="0.3">
            <path
              id="Vector_94"
              fill="#000"
              d="M78.198 244.146v7.782h-1.95l-6.918 21.84H53.988l-6.702-21.624-2.598-.216v-7.782h33.51z"
              opacity="0.3"
            />
          </g>
          <path
            id="Vector_95"
            fill="#263238"
            d="M45.984 252.144c0 .09 6.78.162 15.132.162s15.132-.072 15.132-.162c0-.09-6.774-.162-15.132-.162s-15.132.072-15.132.162z"
          />
          <path
            id="Vector_96"
            fill="#263238"
            d="M34.95 202.074c.32.116.65.204.984.264l2.694.654c2.268.558 5.4 1.41 8.766 2.55 3.366 1.14 6.384 2.34 8.532 3.264l2.538 1.11c.303.153.618.281.942.384a5.925 5.925 0 00-.888-.498c-.6-.3-1.434-.714-2.502-1.2a89.98 89.98 0 00-8.514-3.366 88.337 88.337 0 00-8.82-2.466 58.245 58.245 0 00-2.724-.546c-.33-.08-.668-.13-1.008-.15z"
          />
        </g>
        <g id="floor">
          <path
            id="Vector_97"
            fill="#263238"
            d="M425.676 273.888c0 .09-91.464.162-204.264.162-112.8 0-204.288-.072-204.288-.162 0-.09 91.476-.156 204.276-.156 112.8 0 204.276.072 204.276.156z"
          />
        </g>
        <motion.g
          id="speech-bubble"
          animate={{ y: [4, 0, 4] }}
          transition={{ duration: 1, repeat: Infinity }}
        >
          <path
            id="Vector_98"
            fill="#EEE"
            d="M369.624 45.678H208.83v99.576h135.36l8.382 19.278L360 145.254h9.624V45.678z"
          />
          <path
            id="Vector_99"
            fill="#263238"
            d="M369.6 45.672c0 .966.042 38.4.12 99.6v.12H360l.114-.078a22865.95 22865.95 0 01-7.422 19.278l-.114.306-.132-.3c-2.628-6.036-5.466-12.57-8.4-19.278l.126.084H208.674v-.156V45.51h.144l160.8.138-160.8.144.144-.144v99.6l-.156-.156H344.244l.036.084 8.4 19.284h-.246l7.452-19.26.03-.084h9.684l-.12.12c.12-61.188.12-98.598.12-99.564z"
          />
          <path
            id="Vector_100"
            fill="#263238"
            d="M369.54 55.392c0 .12-35.928.222-80.232.222s-80.238-.102-80.238-.222c0-.12 35.922-.192 80.238-.192s80.232.072 80.232.192z"
          />
          <path
            id="Vector_101"
            fill="#263238"
            d="M217.374 51a1.322 1.322 0 01-1.58 1.294 1.324 1.324 0 01-1.035-1.04 1.312 1.312 0 01.566-1.354 1.322 1.322 0 011.665.169c.246.247.384.582.384.93z"
          />
          <path
            id="Vector_102"
            fill="#263238"
            d="M222.144 51a1.322 1.322 0 01-1.58 1.294 1.317 1.317 0 01-1.035-1.04 1.312 1.312 0 01.566-1.354 1.322 1.322 0 011.665.169c.246.247.384.582.384.93z"
          />
          <path
            id="Vector_103"
            fill="#263238"
            d="M226.914 51a1.32 1.32 0 11-2.64.001 1.32 1.32 0 012.64-.001z"
          />
          <path
            id="Vector_104"
            fill="#263238"
            d="M226.368 73.2l-3.816 1.476 3.816 1.47v.894l-4.8-1.902v-.918l4.8-1.908v.888z"
          />
          <path
            id="Vector_105"
            fill="#263238"
            d="M230.34 69.39h.936l-3.552 10.128h-.924l3.54-10.128z"
          />
          <path
            id="Vector_106"
            fill="#263238"
            d="M236.4 74.22v.924l-4.8 1.902v-.894l3.828-1.47L231.6 73.2v-.894l4.8 1.914z"
          />
          <path
            id="Vector_107"
            fill="#263238"
            d="M237.636 77.766a.717.717 0 01.732-.732.705.705 0 01.667.452c.034.09.05.185.047.28a.73.73 0 01-.2.523.719.719 0 01-1.036.002.733.733 0 01-.21-.525z"
          />
          <path
            id="Vector_108"
            fill="#263238"
            d="M240.084 77.766a.72.72 0 01.449-.681.715.715 0 01.283-.051.712.712 0 01.661.454c.034.088.05.183.047.278a.724.724 0 01-.43.683.722.722 0 01-.802-.156.73.73 0 01-.208-.527z"
          />
          <path
            id="Vector_109"
            fill="#263238"
            d="M242.526 77.766a.72.72 0 01.449-.681.715.715 0 01.283-.051.712.712 0 01.661.454c.034.088.05.183.047.278a.724.724 0 01-.431.683.713.713 0 01-.801-.156.73.73 0 01-.208-.527z"
          />
          <path
            id="Vector_110"
            fill="#455A64"
            d="M327.9 87.426c0 .12-23.766.216-53.082.216-29.316 0-53.088-.096-53.088-.216s23.766-.222 53.088-.222c29.322 0 53.082.096 53.082.222z"
          />
          <path
            id="Vector_111"
            fill="#455A64"
            d="M289.338 96.372c0 .12-15.108.216-33.744.216s-33.738-.096-33.738-.216 15.102-.222 33.738-.222 33.744.096 33.744.222z"
          />
          <path
            id="Vector_112"
            fill="#455A64"
            d="M288.852 129.75c0 .12-15.108.216-33.738.216s-33.744-.096-33.744-.216 15.108-.222 33.744-.222 33.738.102 33.738.222z"
          />
          <path
            id="Vector_113"
            fill="#263238"
            d="M313.854 96.372c0 .12-4.11.216-9.18.216-5.07 0-9.18-.096-9.18-.216s4.11-.222 9.18-.222c5.07 0 9.18.096 9.18.222z"
          />
          <path
            id="Vector_114"
            fill="#13CB6E"
            d="M226.284 100.92c0 .12-1.02.222-2.28.222-1.26 0-2.274-.102-2.274-.222 0-.12 1.02-.216 2.274-.216s2.28.096 2.28.216z"
          />
          <path
            id="Vector_115"
            fill="#263238"
            d="M241.974 101.016c0 .12-2.214.216-4.944.216s-4.944-.096-4.944-.216 2.214-.222 4.944-.222 4.944.102 4.944.222z"
          />
          <path
            id="Vector_116"
            fill="#263238"
            d="M252 101.016c0 .12-1.896.216-4.236.216s-4.236-.096-4.236-.216 1.896-.222 4.236-.222 4.236.102 4.236.222z"
          />
          <path
            id="Vector_117"
            fill="#263238"
            d="M275.874 101.016c0 .12-1.758.216-3.924.216s-3.924-.096-3.924-.216 1.758-.222 3.924-.222 3.924.102 3.924.222z"
          />
          <path
            id="Vector_118"
            fill="#13CB6E"
            d="M225.654 106.728c0 .12-.876.222-1.962.222-1.086 0-1.962-.102-1.962-.222 0-.12.876-.222 1.962-.222 1.086 0 1.962.102 1.962.222z"
          />
          <path
            id="Vector_119"
            fill="#13CB6E"
            d="M225.81 119.442c0 .12-.912.216-2.04.216-1.128 0-2.04-.096-2.04-.216s.912-.222 2.04-.222c1.128 0 2.04.096 2.04.222z"
          />
          <path
            id="Vector_120"
            fill="#263238"
            d="M263.004 119.124c0 .126-6.816.222-15.222.222-8.406 0-15.222-.096-15.222-.222s6.816-.216 15.222-.216c8.406 0 15.222.096 15.222.216z"
          />
          <path
            id="Vector_121"
            fill="#263238"
            d="M260.34 112.692c0 .12-6.504.222-14.52.222s-14.514-.102-14.514-.222c0-.12 6.498-.222 14.514-.222 8.016 0 14.52.102 14.52.222z"
          />
          <path
            id="Vector_122"
            fill="#263238"
            d="M330.492 106.416c0 .12-22.14.216-49.434.216-27.294 0-49.44-.096-49.44-.216s22.128-.222 49.44-.222c27.312 0 49.434.096 49.434.222z"
          />
          <path
            id="Vector_123"
            fill="#EEE"
            d="M379.5 86.4v84.624l-13.158 10.974h68.814V86.4H379.5z"
          />
          <path
            id="Vector_124"
            fill="#263238"
            d="M379.5 86.4H394.434l40.722-.06h.12v.12c0 25.542 0 58.902.036 95.598v.156h-69.366l.306-.258 13.164-10.974-.054.108.072-61.32c0-7.41.03-13.242.036-17.226v-4.536-1.158-.384V92.628c0 3.99 0 9.834.036 17.25 0 14.832.036 36 .066 61.236v.06l-.048.042-13.152 10.986-.096-.258h68.814l-.156.156c0-36.696 0-70.056.036-95.598l.12.114-40.65-.06-11.04-.156h-3.9z"
          />
          <path
            id="Vector_125"
            fill="#263238"
            d="M384.558 90.6a1.128 1.128 0 11-2.255 0 1.128 1.128 0 012.255 0z"
          />
          <path
            id="Vector_126"
            fill="#263238"
            d="M388.638 90.6a1.128 1.128 0 11-2.255 0 1.128 1.128 0 012.255 0z"
          />
          <path
            id="Vector_127"
            fill="#263238"
            d="M392.718 90.6a1.128 1.128 0 11-2.255 0 1.128 1.128 0 012.255 0z"
          />
          <path
            id="Vector_128"
            fill="#13CB6E"
            d="M419.856 116.682c0 .102-7.056.18-15.756.18s-15.756-.078-15.756-.18 7.05-.18 15.756-.18 15.756.078 15.756.18z"
          />
          <path
            id="Vector_129"
            fill="#13CB6E"
            d="M426.432 124.338c0 .096-8.586.18-19.2.18-10.614 0-19.2-.084-19.2-.18 0-.096 8.586-.18 19.2-.18 10.614 0 19.2.078 19.2.18z"
          />
          <path
            id="Vector_130"
            fill="#13CB6E"
            d="M427.992 152.898c0 .102-9.156.18-20.448.18s-20.448-.078-20.448-.18 9.156-.18 20.448-.18 20.448.084 20.448.18z"
          />
          <path
            id="Vector_131"
            fill="#13CB6E"
            d="M391.296 128.232a10.62 10.62 0 01-3.894 0 10.62 10.62 0 013.894 0z"
          />
          <path
            id="Vector_132"
            fill="#13CB6E"
            d="M404.73 128.31c0 .102-1.896.18-4.2.18-2.304 0-4.2-.078-4.2-.18 2.815-.24 5.645-.24 8.46 0h-.06z"
          />
          <path
            id="Vector_133"
            fill="#13CB6E"
            d="M422.724 143.808c0 .102-5.832.18-13.026.18s-13.026-.078-13.026-.18 5.832-.18 13.026-.18 13.026.084 13.026.18z"
          />
          <path
            id="Vector_134"
            fill="#13CB6E"
            d="M393.438 160.524c-1.152.24-2.34.24-3.492 0a8.558 8.558 0 013.492 0z"
          />
          <path
            id="Vector_135"
            fill="#13CB6E"
            d="M393.438 166.302c-1.152.24-2.34.24-3.492 0a8.288 8.288 0 013.492 0z"
          />
          <path
            id="Vector_136"
            fill="#13CB6E"
            d="M425.268 166.032c0 .096-5.832.18-13.026.18s-13.026-.084-13.026-.18c0-.096 5.826-.18 13.026-.18s13.026.078 13.026.18z"
          />
          <path
            id="Vector_137"
            fill="#263238"
            d="M434.634 95.028c0 .096-12.222.18-27.294.18-15.072 0-27.294-.084-27.294-.18 0-.096 12.216-.18 27.294-.18 15.078 0 27.294.078 27.294.18z"
          />
          <path
            id="Vector_138"
            fill="#13CB6E"
            d="M399.858 112.068c-3.84.24-7.692.24-11.532 0 0-.102 2.58-.18 5.766-.18s5.766.078 5.766.18z"
          />
          <path
            id="Vector_139"
            fill="#13CB6E"
            d="M393.33 101.622c0 .102-1.2.18-2.718.18s-2.718-.078-2.718-.18a20.61 20.61 0 015.436 0z"
          />
        </motion.g>
        <g id="person">
          <path
            id="Vector_140"
            fill="#13CB6E"
            d="M345.162 224.856l12.084-11.946-21.552-36.906a20.808 20.808 0 00-8.268-5.754l-7.59-2.886-18.87-3.462-13.392 5.298a8.01 8.01 0 00-5.022 8.4l3.504 29.496 2.064 38.4 48.678-8.448-4.896-14.202.162-22.152 13.098 24.162z"
          />
          <path
            id="Vector_141"
            fill="#263238"
            d="M332.346 221.892c.114 0 .144-7.674.066-17.136-.078-9.462-.228-17.136-.342-17.136-.114 0-.144 7.674-.066 17.136.078 9.462.228 17.136.342 17.136z"
          />
          <path
            id="Vector_142"
            fill="#13CB6E"
            d="M330.564 227.52l-29.4-11.838a6.73 6.73 0 01-4.2-5.724l-2.148-28.2s.252-12-7.236-12.6l-4.266 1.674c-5.514 2.952-6.876 10.968-6.114 17.16 1.062 8.796 1.506 21.246 2.7 28.884.978 6.246 4.728 11.184 10.668 13.35l36.228 7.842 3.768-10.548z"
          />
          <path
            id="Vector_143"
            fill="#263238"
            d="M295.23 186.06c.045.212.075.426.09.642.048.462.114 1.08.192 1.86.15 1.65.36 3.978.6 6.84.24 2.862.54 6.336.87 10.146.078.954.162 1.932.246 2.934.059 1.007.185 2.01.378 3a5.003 5.003 0 001.458 2.64 9.85 9.85 0 002.718 1.686c8.106 3.51 15.6 6.408 21 8.442l6.444 2.4 1.752.642c.207.065.408.149.6.252a4.473 4.473 0 01-.6-.174l-1.8-.6a296.319 296.319 0 01-6.492-2.256c-5.454-1.974-12.942-4.8-21.078-8.346a10.277 10.277 0 01-2.82-1.764 5.35 5.35 0 01-1.566-2.838 22.345 22.345 0 01-.372-3.072c-.084-1-.162-1.978-.234-2.934-.3-3.816-.564-7.254-.792-10.158-.228-2.904-.372-5.19-.498-6.846-.048-.78-.084-1.404-.114-1.866a4.731 4.731 0 01.018-.63z"
          />
          <path
            id="Vector_144"
            fill="#263238"
            d="M278.4 201.39c.042.122.062.25.06.378 0 .294.06.654.096 1.086.09 1.008.21 2.358.348 4.002.138 1.644.366 3.696.66 5.916.29 2.408.735 4.795 1.332 7.146.274 1.21.759 2.361 1.434 3.402.65.964 1.385 1.868 2.196 2.7a18.758 18.758 0 004.71 3.534 17.733 17.733 0 005.166 1.692 1.644 1.644 0 01-.384 0 7.707 7.707 0 01-1.092-.138 14.46 14.46 0 01-3.846-1.308 18.536 18.536 0 01-4.836-3.522 19.058 19.058 0 01-2.256-2.742 10.902 10.902 0 01-1.5-3.516 53.754 53.754 0 01-1.308-7.2c-.27-2.232-.45-4.248-.6-5.94-.15-1.692-.186-3.066-.222-4.014v-1.098a1.506 1.506 0 01.042-.378z"
          />
          <path
            id="Vector_145"
            fill="#263238"
            d="M290.958 221.124a4.282 4.282 0 01.534-1.572 10.091 10.091 0 015.964-5.094 4.292 4.292 0 011.632-.282c0 .066-.6.186-1.536.546a11.771 11.771 0 00-3.336 1.98 11.58 11.58 0 00-2.478 3c-.504.852-.738 1.44-.78 1.422z"
          />
          <path
            id="Vector_146"
            fill="#263238"
            d="M295.32 182.538c-.06 0-.156-.996-.702-2.502a12.668 12.668 0 00-8.916-8.016c-1.56-.384-2.574-.366-2.568-.426.234-.042.472-.052.708-.03.642.026 1.28.108 1.908.246a12.208 12.208 0 019.072 8.154c.206.609.355 1.236.444 1.872.05.23.068.467.054.702z"
          />
          <path
            id="Vector_147"
            fill="#263238"
            d="M330.36 174.54a48.172 48.172 0 011.038 5.484c.348 1.831.591 3.68.726 5.538a50.644 50.644 0 01-1.032-5.49 49.526 49.526 0 01-.732-5.532z"
          />
          <path
            id="Vector_148"
            fill="#13CB6E"
            d="M363.144 262.974s22.608 6.252 23.514 6.6c1.362.48 1.134 5.466-.984 5.4-1.002-.036-17.364.6-19.434.48-2.07-.12-8.304-2.586-8.304-2.586l5.208-9.894z"
          />
          <path
            id="Vector_149"
            fill="#263238"
            d="M372.564 271.596c.114 0 .264-1.2.996-2.4s1.668-1.992 1.596-2.082c-.072-.09-1.164.552-1.926 1.89-.762 1.338-.762 2.604-.666 2.592z"
          />
          <path
            id="Vector_150"
            fill="#263238"
            d="M370.2 270.528c.108 0 .336-1.05.942-2.232.606-1.182 1.314-2.004 1.2-2.082a4.305 4.305 0 00-1.572 1.914c-.57 1.272-.66 2.394-.57 2.4z"
          />
          <path
            id="Vector_151"
            fill="#263238"
            d="M367.992 269.94c.114 0 0-1.14.528-2.4s1.314-2.028 1.236-2.112c-.078-.084-1.062.6-1.584 1.98s-.27 2.592-.18 2.532z"
          />
          <path
            id="Vector_152"
            fill="#263238"
            d="M368.4 267a4.035 4.035 0 00.246-1.686 8.168 8.168 0 00-.57-2.892 1.259 1.259 0 00-.36-.552.608.608 0 00-.394-.124.606.606 0 00-.38.16c-.177.17-.286.398-.306.642-.012.2-.012.4 0 .6.001.374.048.747.138 1.11.152.605.386 1.187.696 1.728.252.512.596.974 1.014 1.362.054-.036-.324-.564-.78-1.488a7.648 7.648 0 01-.6-1.68 5.18 5.18 0 01-.108-1.032c0-.348 0-.78.21-.948a.214.214 0 01.15-.06c.056 0 .11.021.15.06.115.108.2.245.246.396.136.324.25.657.342.996.159.577.259 1.168.3 1.764.042 1.044-.06 1.644.006 1.644z"
          />
          <path
            id="Vector_153"
            fill="#263238"
            d="M368.4 266.79a3.165 3.165 0 00-1.464-.24c-.54 0-1.078.067-1.602.198-.323.07-.634.185-.924.342a.962.962 0 00-.51 1.032 1.12 1.12 0 00.816.804c.333.084.681.084 1.014 0a4.002 4.002 0 001.47-.726 4.16 4.16 0 001.05-1.038c-.42.242-.821.515-1.2.816-.419.287-.89.49-1.386.6a1.73 1.73 0 01-.84 0 .767.767 0 01-.552-.534.6.6 0 01.336-.636c.26-.139.536-.246.822-.318a7.646 7.646 0 011.524-.264c.846-.066 1.404.024 1.446-.036z"
          />
          <path
            id="Vector_154"
            fill="#263238"
            d="M376.038 271.842c.108 0 0-.978.444-2.04s1.2-1.686 1.104-1.77c-.096-.084-.978.444-1.452 1.626s-.192 2.22-.096 2.184z"
          />
          <path
            id="Vector_155"
            fill="#13CB6E"
            d="M336.39 257.724S315 271.728 314.226 272.31c-1.152.87.168 5.016 2.166 4.296.942-.336 18.894-5.334 20.826-6.084 1.932-.75 7.128-4.986 7.128-4.986l-7.956-7.812z"
          />
          <path
            id="Vector_156"
            fill="#263238"
            d="M330.036 268.8c.09 0-.288-1.254-1.434-2.286s-2.4-1.314-2.4-1.2c0 .114 1.092.516 2.154 1.494 1.062.978 1.578 2.046 1.68 1.992z"
          />
          <path
            id="Vector_157"
            fill="#263238"
            d="M331.956 267.066c.09-.03-.324-1.11-1.302-2.1s-2.04-1.44-2.076-1.35c-.036.09.882.66 1.8 1.614.918.954 1.476 1.89 1.578 1.836z"
          />
          <path
            id="Vector_158"
            fill="#263238"
            d="M333.882 265.83c.096 0 0-1.2-.936-2.352s-2.088-1.494-2.106-1.398c-.018.096.972.6 1.8 1.638.828 1.038 1.134 2.142 1.242 2.112z"
          />
          <path
            id="Vector_159"
            fill="#263238"
            d="M332.616 263.172c.054 0-.24-.6-.486-1.602a9.357 9.357 0 01-.252-1.77 8.858 8.858 0 010-1.056c0-.157.04-.312.114-.45a.228.228 0 01.134-.091.223.223 0 01.16.025c.246.096.372.51.486.84.106.33.176.669.21 1.014.055.6.037 1.204-.054 1.8-.15 1.014-.348 1.632-.288 1.65.283-.495.47-1.038.552-1.602a6.836 6.836 0 00.138-1.86 4.878 4.878 0 00-.414-1.656.995.995 0 00-.48-.516.601.601 0 00-.75.198 1.296 1.296 0 00-.18.636 8.473 8.473 0 000 1.104c.042.611.157 1.216.342 1.8.139.562.402 1.087.768 1.536z"
          />
          <path
            id="Vector_160"
            fill="#263238"
            d="M342.144 266.292a6.754 6.754 0 00-.942.648 27.377 27.377 0 01-2.634 1.614 35.219 35.219 0 01-4.2 1.866c-1.62.6-3.438 1.17-5.358 1.728a975.28 975.28 0 00-9.852 2.88l-3 .912a6.107 6.107 0 00-1.074.384 7.102 7.102 0 001.116-.246l3-.792c2.532-.69 6-1.662 9.876-2.772 1.926-.558 3.75-1.14 5.4-1.77a31.652 31.652 0 004.2-1.956 20.53 20.53 0 002.598-1.734c.316-.223.608-.478.87-.762z"
          />
          <path
            id="Vector_161"
            fill="#263238"
            d="M342.936 266.58a5.853 5.853 0 00-1.8-.48 7.476 7.476 0 00-4.494.546 5.397 5.397 0 00-1.926 1.656 9.01 9.01 0 00-1.002 1.8 6.982 6.982 0 00-.6 1.8c.317-.534.595-1.089.834-1.662.51-.996 1.314-2.454 2.856-3.222a7.916 7.916 0 014.296-.6c1.128.066 1.824.222 1.836.162z"
          />
          <path
            id="Vector_162"
            fill="#263238"
            d="M380.862 222.696s6.414 2.304 6.444 9.504c.048 13.8-27.852 28.98-27.852 28.98l-37.134-24.6-2.328-.942s50.124-18.852 60.87-12.942z"
          />
          <path
            id="Vector_163"
            fill="#455A64"
            d="M341.13 249c.342-.186.666-.405.966-.654.6-.438 1.476-1.098 2.544-1.92a210.393 210.393 0 008.13-6.642 209.96 209.96 0 007.8-7.032c.972-.924 1.752-1.686 2.28-2.22a6.31 6.31 0 00.792-.858 7.29 7.29 0 00-.894.75l-2.358 2.13a400.632 400.632 0 01-7.866 6.942 436.65 436.65 0 01-8.046 6.726L342 248.214a7.205 7.205 0 00-.87.786z"
          />
          <path
            id="Vector_164"
            fill="#455A64"
            d="M367.2 225.672a4.56 4.56 0 00-2.196 1.656c-.99 1.2-1.254 2.49-1.158 2.502.096.012.516-1.116 1.452-2.262.936-1.146 1.938-1.794 1.902-1.896z"
          />
          <path
            id="Vector_165"
            fill="#263238"
            d="M382.902 269.322c0-.042-.444-.132-.96.288a3.473 3.473 0 00-1.068 2.154 4.154 4.154 0 00.336 2.4c.264.564.552.834.6.804.048-.03-.15-.348-.336-.9a4.902 4.902 0 01-.21-2.226 3.727 3.727 0 01.876-2.01c.378-.432.768-.432.762-.51z"
          />
          <path
            id="Vector_166"
            fill="#B0B9CB"
            d="M378.204 203.118l-10.56 28.482a4.107 4.107 0 01-3.9 2.676l-58.152-.768v-4.416l21.6.786 9.156-28.404a2.33 2.33 0 012.214-1.608l37.512.108a2.324 2.324 0 012.13 3.144z"
          />
          <path
            id="Vector_167"
            fill="#263238"
            d="M378.204 203.118v-.09c.032-.086.056-.174.072-.264a2.242 2.242 0 000-1.074 2.278 2.278 0 00-2.076-1.704h-37.296a2.558 2.558 0 00-1.548.342 2.274 2.274 0 00-.966 1.266l-9.132 28.356v.066h-.072l-21.6-.78.096-.096v4.416l-.09-.09 36.564.486 15.894.222 3.666.048h1.8a4.206 4.206 0 001.686-.246 4.01 4.01 0 002.334-2.244c.384-1.032.762-2.046 1.134-3.03 2.946-7.902 5.328-14.286 6.972-18.702.828-2.196 1.464-3.906 1.902-5.07.438-1.164.672-1.758.672-1.758s-.21.6-.636 1.8L375.696 210c-1.632 4.422-3.99 10.8-6.918 18.72l-1.128 3.042a4.164 4.164 0 01-2.4 2.322 4.399 4.399 0 01-1.746.264h-1.8l-3.66-.048-15.894-.198-36.57-.474h-.09v-4.608h.096l21.6.792-.09.066 9.156-28.35a2.4 2.4 0 011.032-1.35 2.709 2.709 0 011.632-.36h9.396l10.962.048 15.648.072h1.296a2.329 2.329 0 012.106 1.758c.089.355.089.726 0 1.08a2.07 2.07 0 01-.12.342z"
          />
          <path
            id="Vector_168"
            fill="#EBEBEB"
            d="M352.422 213.876a2.614 2.614 0 012.826.3 2.299 2.299 0 01.552 2.508 2.465 2.465 0 01-2.124 1.482 2.223 2.223 0 01-2.244-1.482c-.27-.93 0-2.316.99-2.808z"
          />
          <path
            id="Vector_169"
            fill="#263238"
            d="M365.712 262.71s-52.404-33.564-54.714-34.884c-1.968-1.122-7.236-4.362-12.426-2.928-5.19 1.434-9.846 6.102-12.174 12.888-1.326 3.888-1.956 10.518-2.25 15.492a22.977 22.977 0 005.4 16.386 14.71 14.71 0 006.54 4.644c.546.168 1.116.312 1.698.438 9.918 2.154 20.112-2.298 25.686-10.8l1.2-1.86s34.32 12.6 34.854 12.426l6.186-11.802z"
          />
          <path
            id="Vector_170"
            fill="#455A64"
            d="M313.704 229.686s-.048 0-.132-.078l-.366-.252a67.62 67.62 0 00-1.416-.948 28.083 28.083 0 00-5.568-2.826 14.844 14.844 0 00-4.2-.882 12.007 12.007 0 00-4.8.786 15.437 15.437 0 00-4.632 2.808 21.12 21.12 0 00-2.058 2.022 14.885 14.885 0 00-1.704 2.4 24.598 24.598 0 00-2.256 5.4c-.498 1.83-.894 3.636-1.266 5.34a48.586 48.586 0 00-1.2 9.192 33.383 33.383 0 00.474 6.234c.114.732.246 1.29.324 1.674l.084.438c.008.05.008.1 0 .15a.716.716 0 01-.048-.144c0-.114-.072-.258-.114-.432-.102-.378-.252-.93-.384-1.662a31.225 31.225 0 01-.6-6.258 47.07 47.07 0 011.158-9.234c.372-1.716.756-3.51 1.248-5.364a25.306 25.306 0 012.286-5.472 15.302 15.302 0 011.74-2.466 21.128 21.128 0 012.094-2.052 15.734 15.734 0 014.728-2.844 12.248 12.248 0 014.938-.78c1.453.107 2.884.422 4.248.936a26.287 26.287 0 015.562 2.934c.6.42 1.074.762 1.386 1.002l.348.276c.047.027.09.062.126.102z"
          />
          <path
            id="Vector_171"
            fill="#455A64"
            d="M300.81 253.236c.093.049.19.094.288.132l.858.336L305.28 255l12.36 4.656c5.25 1.956 11.544 4.29 18.6 6.858 7.056 2.568 14.874 5.4 23.226 8.208l.156.048.072-.138c1.308-2.508 2.694-5.148 4.098-7.83l2.088-4.002.084-.156-.15-.09L325.8 237.15l-12-7.584-3.264-2.04-.852-.516a3.28 3.28 0 00-.294-.168s.09.072.276.198l.834.552 3.228 2.094 11.958 7.68 39.948 25.506-.066-.246a1151.62 1151.62 0 01-2.088 3.99l-4.092 7.83.222-.096c-8.346-2.82-16.17-5.622-23.232-8.166l-18.6-6.774-12.402-4.548-3.348-1.2-.87-.306-.348-.12z"
          />
          <path
            id="Vector_172"
            fill="#0A8647"
            d="M303.522 141.882a26.871 26.871 0 01-2.328-5.574 9.082 9.082 0 01.312-5.928c.486-1.074 1.254-2.016 1.638-3.132.522-1.536.306-3.348 1.26-4.662 1.482-2.058 4.662-1.578 6.96-2.652 1.8-.84 3.042-2.646 4.92-3.252 1.644-.528 3.438 0 5.022.66s3.138 1.542 4.848 1.764c1.229.069 2.461.069 3.69 0a4.855 4.855 0 013.402 1.2c.864.87 1.044 2.484.108 3.282 2.154-.366 4.2 1.8 4.152 3.966-.048 2.166-1.674 4.104-3.66 5.016-1.986.912-4.248.966-6.426.792-6.162-.48-12.354-2.82-16.548-7.356"
          />
          <path
            id="Vector_173"
            fill="#FFBE9D"
            d="M304.848 134.172l-4.2 30.51a9.143 9.143 0 00.241 3.677 9.174 9.174 0 004.501 5.645 9.163 9.163 0 0010.047-.919 9.163 9.163 0 003.361-5.919c.312-2.298.6-4.2.6-4.446 0 0 7.866.336 9.486-7.272.78-3.678 1.8-11.4 2.592-18.366a14.489 14.489 0 00-11.52-15.882l-.738-.144c-8.028-1.134-13.47 5.058-14.37 13.116z"
          />
          <path
            id="Vector_174"
            fill="#EB996E"
            d="M319.47 162.6a18.13 18.13 0 01-9.384-4.8s1.254 5.964 9 6.798l.384-1.998z"
          />
          <path
            id="Vector_175"
            fill="#263238"
            d="M328.092 143.568a1.108 1.108 0 01-1.272.888 1.08 1.08 0 01-.398-.131 1.078 1.078 0 01-.501-.651 1.07 1.07 0 01-.025-.418 1.113 1.113 0 011.266-.894 1.057 1.057 0 01.906.785c.036.137.044.28.024.421z"
          />
          <path
            id="Vector_176"
            fill="#263238"
            d="M329.124 140.496c-.162.114-.864-.66-2.04-.888-1.176-.228-2.148.18-2.244 0-.048-.078.138-.306.6-.504a2.997 2.997 0 011.8-.186c.611.119 1.168.43 1.59.888.306.36.378.642.294.69z"
          />
          <path
            id="Vector_177"
            fill="#263238"
            d="M317.16 141.678a1.108 1.108 0 01-1.272.888 1.048 1.048 0 01-.716-.405 1.047 1.047 0 01-.208-.795 1.106 1.106 0 011.266-.888 1.058 1.058 0 01.904.781c.036.136.045.279.026.419z"
          />
          <path
            id="Vector_178"
            fill="#263238"
            d="M317.562 138.414c-.168.114-.87-.66-2.046-.894-1.176-.234-2.148.186-2.244 0-.048-.078.138-.306.6-.504a2.997 2.997 0 011.8-.186c.61.12 1.166.428 1.59.882.306.372.378.648.3.702z"
          />
          <path
            id="Vector_179"
            fill="#263238"
            d="M320.076 147.96a7.674 7.674 0 011.974.036c.306.03.6 0 .69-.168a1.538 1.538 0 000-.93c-.132-.78-.276-1.596-.426-2.454-.6-3.492-.936-6.336-.798-6.36.138-.024.726 2.79 1.308 6.282.132.858.264 1.674.39 2.46a1.8 1.8 0 01-.078 1.2.78.78 0 01-.6.342 1.988 1.988 0 01-.522-.036 7.763 7.763 0 01-1.938-.372z"
          />
          <path
            id="Vector_180"
            fill="#263238"
            d="M316.464 148.08c.192 0-.06 1.302.852 2.4.912 1.098 2.292 1.254 2.262 1.434 0 .084-.348.186-.924.09a3.252 3.252 0 01-1.908-1.128 2.82 2.82 0 01-.6-2.01c.054-.522.234-.81.318-.786z"
          />
          <path
            id="Vector_181"
            fill="#13CB6E"
            d="M318.78 133.878c-.18.294-1.32-.09-2.73-.198-1.41-.108-2.592.042-2.718-.276-.054-.156.198-.414.708-.636a4.792 4.792 0 014.152.366c.462.3.666.606.588.744z"
          />
          <path
            id="Vector_182"
            fill="#13CB6E"
            d="M329.4 137.016c-.264.228-1.014-.21-1.98-.42s-1.8-.18-1.968-.492c-.054-.156.126-.396.54-.6a2.925 2.925 0 013.174.738c.264.366.324.666.234.774z"
          />
          <path
            id="Vector_183"
            fill="#FFBE9D"
            d="M304.344 139.944c-.12-.084-4.932-2.598-5.808 2.652-.876 5.25 4.578 5.046 4.608 4.896.03-.15 1.2-7.548 1.2-7.548z"
          />
          <path
            id="Vector_184"
            fill="#EB996E"
            d="M301.872 145.236s-.102.048-.264.09a.929.929 0 01-.69-.096c-.522-.3-.84-1.254-.708-2.208.063-.452.225-.884.474-1.266a1.093 1.093 0 01.804-.6.476.476 0 01.498.342c.048.162 0 .258 0 .27 0 .012.126-.066.12-.288a.598.598 0 00-.15-.366.695.695 0 00-.486-.216 1.3 1.3 0 00-1.092.654 3.118 3.118 0 00-.558 1.41c-.144 1.062.234 2.142.954 2.49a1.01 1.01 0 00.864 0c.204-.09.252-.204.234-.216z"
          />
          <path
            id="Vector_185"
            fill="#0A8647"
            d="M333 129.996c-.9 1.938-5.532 2.688-8.928 1.662-3.126-.942-4.266-2.928-6.252-4.662-1.26-1.098-1.26-1.098-3.318-1.308-1.662-.174-3 1.482-4.254 2.58.702 4.2-1.152 8.304-2.964 12.126a2.1 2.1 0 01-.846 1.086c-.708.348-1.56-.204-1.986-.876a6.597 6.597 0 01-.738-3.684c.017-2.904.61-5.775 1.746-8.448a14.84 14.84 0 015.484-6.6 14.104 14.104 0 0110.44-1.728 17.328 17.328 0 019.15 5.49 10.115 10.115 0 012.466 4.332"
          />
          <path
            id="Vector_186"
            fill="#263238"
            d="M322.344 142.68c.048 0 0-.45.24-1.2a5.113 5.113 0 011.86-2.7 4.937 4.937 0 014.644-.666 4.865 4.865 0 012.262 1.71 4.865 4.865 0 010 5.754 4.858 4.858 0 01-2.262 1.71 4.93 4.93 0 01-4.644-.672 5.081 5.081 0 01-1.86-2.694c-.21-.768-.192-1.2-.24-1.2-.014.11-.014.221 0 .33-.002.313.034.626.108.93a5.024 5.024 0 001.836 2.898 5.216 5.216 0 005.87.319 5.226 5.226 0 001.522-1.399 5.222 5.222 0 000-6.246 5.217 5.217 0 00-5.559-1.955 5.205 5.205 0 00-1.857.923 5.023 5.023 0 00-1.836 2.892c-.074.307-.11.621-.108.936-.007.111.001.222.024.33z"
          />
          <path
            id="Vector_187"
            fill="#263238"
            d="M309.894 140.508c.048 0 0-.45.24-1.2a5.089 5.089 0 011.86-2.7 4.94 4.94 0 012.238-.921 4.94 4.94 0 012.406.255c.907.33 1.694.923 2.262 1.704a4.872 4.872 0 010 5.76 4.805 4.805 0 01-2.262 1.704 4.928 4.928 0 01-4.644-.666 5.093 5.093 0 01-1.86-2.7c-.21-.762-.192-1.2-.24-1.2a1.255 1.255 0 000 .324c-.002.315.034.629.108.936a5.027 5.027 0 001.836 2.898 5.232 5.232 0 007.422-1.032 5.24 5.24 0 000-6.252 5.217 5.217 0 00-5.559-1.955 5.205 5.205 0 00-1.857.923 5.045 5.045 0 00-1.836 2.898c-.074.306-.11.621-.108.936a1.156 1.156 0 00-.006.288z"
          />
          <path
            id="Vector_188"
            fill="#263238"
            d="M320.154 140.688c.054.102.72-.21 1.494.03s1.164.858 1.266.798c.102-.06 0-.222-.15-.486a1.855 1.855 0 00-2.214-.666c-.294.12-.426.276-.396.324z"
          />
        </g>
      </g>
    </svg>
  );
}
